
import React from 'react'
import moment from 'moment'

// components
import {
  CCol,
  CRow,
  CFormCheck,
  CFormLabel
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

// helpers
import { couponTypeText } from 'src/helpers/text-format'
import { formatPriceNumber } from 'src/helpers/format-data'
import { useSelector } from 'react-redux'

const AppFirstDetails = ({
  couponDetails,
}) => {
  const currency = useSelector((state) => state.currency)

  const couponValue = couponDetails?.product_promos?.length > 0
    ? couponDetails?.product_promos?.[0]?.percentage_discount
      ? `${couponDetails?.product_promos?.[0]?.percentage_discount}%`
      : formatPriceNumber(couponDetails?.product_promos?.[0]?.value_discount, currency?.code)
    : couponDetails?.combination_promos?.[0]?.percentage_discount
      ? `${couponDetails?.combination_promos?.[0]?.percentage_discount}%`
      : formatPriceNumber(couponDetails?.combination_promos?.[0]?.value_discount, currency?.code)

  const listFreeShipping = (array) => {
    let newArray = []
    array.forEach(item => {
      newArray.push(item?.shipping_method && item?.shipping_method?.name)
    });

    if (newArray.length > 1) {
      return newArray.join(", ")
    } else if (newArray.length === 1) {
      return newArray[0]
    } else {
      return "-"
    }
  }

  return (
    <CRow>
      <CCol lg='6'>
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Coupon Name"
          subtitle={couponDetails?.title}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3 text-justify"
          title="Coupon Description"
          subtitle={couponDetails?.description}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Coupon Code"
          subtitle={couponDetails?.promo_code}
        />
        <AppImageDetail
          colSize="12"
          className="mb-3"
          title="Coupon Image"
          productImages={couponDetails?.image_url}
        />

        <CCol lg='12' className='mb-3'>
          <CFormLabel>
            Status
          </CFormLabel>
          <CFormCheck
            label="Active"
            checked={couponDetails?.is_active}
            disabled
          />
        </CCol>

      </CCol>

      <CCol lg='6'>
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Coupon Type"
          subtitle={couponTypeText(couponDetails?.promo_type)}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Quantity"
          subtitle={couponDetails?.quota}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title={couponDetails?.promo_type === "free-shipping" ? "Free Shipping List" : "Coupon Value"}
          subtitle={couponDetails?.promo_type === "free-shipping" ? listFreeShipping(couponDetails?.free_shipping_promos) : couponValue}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Validity Period"
          subtitle={couponDetails?.expired_start_date || couponDetails?.expired_end_date ? `
          ${couponDetails?.expired_start_date ? moment(couponDetails?.expired_start_date).format('DD/MM/YYYY') : ""}
          ${couponDetails?.expired_end_date ? `- ${moment(couponDetails?.expired_end_date).format('DD/MM/YYYY')}` : ""}
          `: "Valid until disabled"}
        />
      </CCol>
    </CRow>
  )
}

export default AppFirstDetails
