import React from 'react'

// Dashboard
import Dashboard from './views/dashboard/Dashboard'

// Coupons
import CouponsList from './views/coupons/CouponsList'
import AddCoupon from './views/coupons/AddCoupon'
import EditCoupon from './views/coupons/EditCoupon'
import CouponDetails from './views/coupons/CouponDetails'

// Buttons
import MyPage from './views/my-page/MyPage'
import EditMyPage from './views/my-page/EditMyPage'

// Orders
import OrderList from './views/orders/OrderList'
import OrderDetails from './views/orders/OrderDetails'

// Products
import ProductList from './views/products/ProductList'
import AddProduct from './views/products/AddProduct'
import EditProduct from './views/products/EditProduct'
import ProductDetails from './views/products/ProductDetails'

// Products - Variant
// import VariantList from './views/products/variants/VariantList'

// Products - Brand
import BrandList from './views/products/brands/BrandList'
import AddBrand from './views/products/brands/AddBrand'
import EditBrand from './views/products/brands/EditBrand'
import BrandDetails from './views/products/brands/BrandDetails'

// Products - Category
import CategoryList from './views/products/category/CategoryList'
import AddCategory from './views/products/category/AddCategory'
import EditCategory from './views/products/category/EditCategory'
import CategoryDetails from './views/products/category/CategoryDetails'

// Products - Sub Category
import SubCategoryList from './views/products/sub-category/SubCategoryList'
import AddSubCategory from './views/products/sub-category/AddSubCategory'
import EditSubCategory from './views/products/sub-category/EditSubCategory'
import SubCategoryDetails from './views/products/sub-category/SubCategoryDetails'

// Customers
import CustomerList from './views/customers/CustomerList'
import CustomerDetails from './views/customers/CustomerDetails'

// Chats
import Chats from './views/chats/Chat'

// Reports
import Reports from './views/reports/Reports'

const routes = [
  { path: '/', name: 'Home', element: Dashboard, exact: true, },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard, exact: true },
  { path: '/coupons', name: 'Coupons', element: CouponsList, exact: true },
  { path: '/coupons/list', name: 'Coupon List', element: CouponsList },
  { path: '/coupons/add', name: 'Add Coupon', element: AddCoupon },
  { path: '/coupons/edit/:id', name: 'Edit Coupon', element: EditCoupon },
  { path: '/coupons/details/:id', name: 'Coupon Details', element: CouponDetails },
  { path: '/my-page', name: 'My Page', element: MyPage, exact: true },
  { path: '/my-page/edit/general-settings', name: 'Edit General Setting', element: EditMyPage },
  { path: '/orders', name: 'Orders', element: OrderList, exact: true },
  { path: '/orders/list', name: 'Order List', element: OrderList },
  { path: '/orders/details/:id', name: 'Order Details', element: OrderDetails },
  { path: '/products', name: 'Product', element: ProductList, exact: true, },
  { path: '/products/list', name: 'Product List', element: ProductList },
  { path: '/products/add', name: 'Add Product', element: AddProduct },
  { path: '/products/edit/:id', name: 'Edit Product', element: EditProduct },
  { path: '/products/details/:id', name: 'Product Details', element: ProductDetails },
  // { path: '/products/variant/list', name: 'Variant List', element: VariantList },
  { path: '/products/category/list', name: 'Category List', element: CategoryList },
  { path: '/products/category/add', name: 'Add Category', element: AddCategory },
  { path: '/products/category/edit/:id', name: 'Edit Category', element: EditCategory },
  { path: '/products/category/details/:id', name: 'Category Details', element: CategoryDetails },
  { path: '/products/sub-category/list', name: 'Sub Category List', element: SubCategoryList },
  { path: '/products/sub-category/add', name: 'Add Sub Category', element: AddSubCategory },
  { path: '/products/sub-category/edit/:id', name: 'Edit Sub Category', element: EditSubCategory },
  { path: '/products/sub-category/details/:id', name: 'Sub Category Details', element: SubCategoryDetails },
  { path: '/products/brand/list', name: 'Brand List', element: BrandList },
  { path: '/products/brand/add', name: 'Add Brand', element: AddBrand },
  { path: '/products/brand/edit/:id', name: 'Edit Brand', element: EditBrand },
  { path: '/products/brand/details/:id', name: 'Brand Details', element: BrandDetails },
  { path: '/customers', name: 'Customers', element: CustomerList, exact: true },
  { path: '/customers/list', name: 'Customer List', element: CustomerList },
  { path: '/customers/details/:id', name: 'Customer Details', element: CustomerDetails },
  { path: '/chat', name: 'Chat', element: Chats, exact: true },
  { path: '/reports', name: 'Reports', element: Reports, exact: true },
]

export default routes
