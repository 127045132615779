import Axios from "./axios";

export const getArrayCombinationVariantID = async (productId, combinations) => {
  try {
    let arrayCombinations = []

    // get variant type by product id
    const productVariant = await Axios.get(`/api/ecommerce/v1/products/variant/product/id/${productId}`)

    if (combinations.length > 0) {
      for (let i = 0; i < combinations.length; i++) {
        const foundVariantType = productVariant.data.filter(variant => variant.name == combinations[i].variant_type)

        // get variant option by variant id
        const productVariantOption = await Axios.get(`/api/ecommerce/v1/products/variant/option/variant/id/${foundVariantType[0].id}`)
        const foundVariantOption = productVariantOption.data.filter(option => option.name == combinations[i].variant_option)

        arrayCombinations.push({
          variant_id: foundVariantType[0].id,
          variant_option_id: foundVariantOption[0].id
        })
      }
    }

    return arrayCombinations;
  } catch (error) {
    console.error(error)
  }
}
