
import React from 'react'
import { useSelector } from "react-redux"

// components
import {
  CInputGroup,
  CCol,
  CInputGroupText,
  CRow,
  CFormLabel,
  CFormInput
} from '@coreui/react-pro'

const FormProductPrice = ({
  id,
  name,
  colSize,
  className,
  inputLabel,
  placeholder,
  priceCurrency,
  value,
  handlePriceInput,
  feedbackInvalid,
  invalid,
  combinationIndex
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      <CRow>
        <CInputGroup>
          <CInputGroupText>{currency?.code}</CInputGroupText>
          <CFormInput
            id={id}
            name={name}
            placeholder={placeholder}
            type="text"
            value={value}
            onChange={(e) => handlePriceInput(e, combinationIndex)}
            feedbackInvalid={feedbackInvalid}
            invalid={invalid}
          />
        </CInputGroup>
      </CRow>
    </CCol>
  )
}

export default FormProductPrice
