import React from 'react'
import { useSelector } from "react-redux"

import {
  CRow,
  CCol,
  CImage,
  CFormText,
  CFormLabel,
} from '@coreui/react-pro'
import AppCard from 'src/components/AppCard'

const AppCombinationDetails = ({
  productCombinations
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <div>
      {productCombinations?.length > 0 ? (
        <div>
          <h5 className="mt-2" style={{ color: "#8A93A2" }}>{`${productCombinations?.length} Product Combinations`}</h5>
          <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 3 }} md={{ cols: 2 }}>
            {productCombinations?.map((item, index) => {
              return (
                <CCol xs>
                  <AppCard
                    headerTitle={<h5>{`Product Combination ${index + 1} (${item?.combination_sku})`}</h5>}
                    bodyContent={
                      <CRow>
                        <CCol lg='8'>
                          {item?.combination_options?.length > 0 && (
                            <CRow>
                              {item?.combination_options?.map((option, index) => {
                                return (
                                  <CCol lg='3' key={index}>
                                    <CFormLabel className="mt-2">{option?.variant?.name}</CFormLabel>
                                    <CFormText>{option?.variant_option?.name}</CFormText>
                                  </CCol>
                                )
                              })}
                            </CRow>
                          )}
                          <CRow className='mt-3'>
                            <CCol lg='6'>
                              <CFormLabel>Quantity</CFormLabel>
                              <CFormText>{`${item?.qty} Products`}</CFormText>
                            </CCol>
                            <CCol lg='6'>
                              <CFormLabel>Weight</CFormLabel>
                              <CFormText>{`${item?.weight} ${item?.weight_unit}`}</CFormText>
                            </CCol>
                          </CRow>
                          <CRow className='mt-3'>
                            <CCol lg='6'>
                              <CFormLabel>Discount Type</CFormLabel>
                              <CFormText>
                                {
                                  item?.discounts[0]?.percentage_discount ? "Percentage"
                                    : item?.discounts[0]?.value_discount ? "Fixed Amount"
                                      : "-"
                                }
                              </CFormText>
                            </CCol>
                            <CCol lg='6'>
                              <CFormLabel>Discount Value</CFormLabel>
                              <CFormText>
                                {
                                  item?.discounts[0]?.percentage_discount ? `${item?.discounts[0]?.percentage_discount} %`
                                    : item?.discounts[0]?.value_discount ? `${currency?.code} ${item?.discounts[0]?.value_discount}`
                                      : "-"
                                }
                              </CFormText>
                            </CCol>
                          </CRow>
                        </CCol>
                        {item?.image_url && (
                          <CCol lg='4'>
                            <CImage className='border p-1' rounded src={item?.image_url} width={125} height={125} style={{ objectFit: "contain" }} />
                          </CCol>
                        )}
                      </CRow>
                    }
                    key={index}
                  />
                </CCol>
              )
            })}
          </CRow>
        </div>
      ) : (
        <div className='my-5 text-center'>
          <h5><b>There is no combination in this product yet</b></h5>
        </div>
      )}
    </div>
  )
}

export default AppCombinationDetails
