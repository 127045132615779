import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Axios from "../helpers/axios";

const LoginGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("ut");

  const handleLogout = () => {
    Axios({
      method: 'post',
      url: '/api/sso/v1/users/logout'
    })
      .then(() => {
        Cookies.remove('ut', { path: '/', secure: true });
        dispatch({ type: 'SET_USER', user: {} })
        navigate('/login')
      })
      .catch((err) => {
        console.error(err)
        let errMsg = err.response.data.message
        if (errMsg === 'Session expired') {
          Cookies.remove('ut', { path: '/', secure: true });
          dispatch({ type: 'SET_USER', user: {} })
          navigate('/login')
        }
      })
  }

  useEffect(() => {
    if (token) {
      Axios({
        method: "get",
        url: "/api/sso/v1/users/profile",
      })
        .then(({ data }) => {
          if (data) {
            const { roles } = data || {};
            if (roles) {
              let role = roles[0];
              if (role && role.id && role.id === 1) {
                dispatch({ type: "SET_USER", user: data });
              } else {
                handleLogout()
              }
            } else {
              handleLogout()
            }
          } else {
            handleLogout()
          }
        })
        .catch((err) => {
          console.error(err);
          handleLogout()
        });
    } else {
      navigate("/login");
    }

    Axios({
      method: "get",
      url: `/api/ecommerce/v1/settings`,
    })
      .then(({ data }) => {
        const dataSettings = data && data[0];
        dispatch({ type: "SET_GENERAL_SETTING", currency: dataSettings });
        Axios({
          method: "get",
          url: `/api/ecommerce/v1/currencies`,
        })
          .then(({ data }) => {
            const shopCurrency = data?.filter((currency) => {
              return currency?.id == dataSettings?.shop_currency_id;
            });
            dispatch({ type: "SET_CURRENCY", currency: shopCurrency[0] });
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  if (!token) {
    navigate("/login");
  } else {
    return children;
  }

  // const afterRegister = localStorage.getItem("after_register");

  // useEffect(() => {
  //   localStorage.setItem("credentials", "");
  //   if (token) {
  //     if (afterRegister && Boolean(afterRegister)) {
  //       localStorage.setItem("after_register", "");
  //       localStorage.setItem("email_storage", "");
  //       Cookies.remove("ut");
  //       dispatch({ type: "SET_USER", user: {} });
  //       navigate("/login");
  //     } else {
  //       Axios({
  //         method: "get",
  //         url: "/users",
  //       })
  //         .then(({ data }) => {
  //           if (data) {
  //             dispatch({ type: "SET_USER", user: data });
  //           } else {
  //             navigate("/login");
  //           }
  //         })
  //         .catch((err) => {
  //           navigate("/login");
  //         });
  //     }
  //   }
  // }, []);

  // if (!token) {
  //   return <Navigate to="login" replace />;
  // } else {
  //   if (Boolean(afterRegister) === false && user) {
  //     return children;
  //   }
  // }
};

export default LoginGuard;
