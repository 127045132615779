
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormText,
  CImage,
  CRow
} from '@coreui/react-pro'

import ExampleImage1 from '../../../assets/images/ExampleImage1.png'

const AppImageDetail = ({
  colSize,
  className,
  title,
  productImages,
  productDetailsPage
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CCol lg='12'>
        <CFormLabel>
          {title}
        </CFormLabel>
      </CCol>
      {productImages && !productDetailsPage && (
        <CCol lg='12'>
          <CImage
            rounded
            className='mt-2 border p-1'
            src={productImages}
            width={300}
            height={300}
            style={{ objectFit: "contain" }}
          />
        </CCol>
      )}
      {productImages?.length > 0 && productDetailsPage && (
        <CCol lg='12'>
          {productImages?.map((image, index) => {
            return (
              <CImage
                rounded
                className='me-3 border p-1'
                src={image?.image_url}
                width={300}
                height={300}
                key={index}
                style={{ objectFit: "contain" }}
              />
            )
          })}
        </CCol>
      )}
      {(!productImages || productImages <= 0) && (
        <CFormText>
          No image provided
        </CFormText>
      )}
    </CCol>
  )
}

export default AppImageDetail
