import React, { Component, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import {
  CSpinner,
  CToaster,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/react-pro";

// Containers
import DefaultLayout from './layout/DefaultLayout'

// Pages
import Login from './views/pages/login/Login'
import Register from './views/pages/register/Register'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'

function App() {
  const dispatch = useDispatch();

  const toastMessage = useSelector((state) => state.toastMessage);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
  return (
    <div className="App">
      <CToaster placement="top-end">
        <CToast
          autohide={true}
          visible={toastMessage.isShown}
          color={toastMessage.color}
          className="text-white align-items-center"
          onClose={() => {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: false,
                isShown: false,
                color: "primary",
                text: "",
              },
            });
          }}
        >
          <div className="d-flex">
            <CToastBody>{toastMessage.text}</CToastBody>
            <CToastClose className="me-2 m-auto" white />
          </div>
        </CToast>
      </CToaster>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App
