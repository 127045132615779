import React from 'react'

// components
import AppCard from 'src/components/AppCard.js'
import AppCardHeader from 'src/components/card/AppCardHeader'
import PurchaseDetails from './PurchaseDetails'

const AppSecondDetails = ({
  orderDetails,
  shippingAddressDetails
}) => {

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h5>Purchase Details</h5>}
          headerDownloadButton="Download Receipt"
          downloadLink={orderDetails?.invoice?.file_url}
          downloadName={`INVOICE ${orderDetails?.transaction_id}`}
        />
      }
      bodyContent={
        <PurchaseDetails
          orderDetails={orderDetails}
          shippingAddressDetails={shippingAddressDetails}
        />
      }
    />
  )
}

export default AppSecondDetails
