import React, { useState } from 'react'

// components
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// icons
import Shirt1 from '../../../assets/images/Shirt1.png'

// helpers
import { columnProductList } from 'src/components/table-column/TableColumnList'

const AppSecondDetails = ({
  brandProducts
}) => {
  const [brandListPerPage, setBrandListPerPage] = useState(10);
  const [searchBrandListFilter, setSearchBrandListFilter] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const tempBrandProduct = brandProducts?.map((d) => ({
    ...d,
    product_main_image: d?.images[0]?.image_url,
    category_name: d?.sub_category?.category?.name || 'Not Available',
    brand_name: d?.brand?.name || 'Not Available'
  }));

  return (
    <>
      {showConfirmDelete && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='product'
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
        />
      )}
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>Product Under This Brand</h4>}
        bodyContent={
          <AppTable
            productListPage
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnProductList}
            tableContent={tempBrandProduct}
            ontentPerPage={brandListPerPage}
            setContentPerPage={setBrandListPerPage}
            searchContentFilter={searchBrandListFilter}
            setSearchContentFilter={setSearchBrandListFilter}
          />
        }
      />
    </>
  )
}

export default AppSecondDetails
