
import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CImage,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CMultiSelect,
  CFormText,
  CTooltip
} from '@coreui/react-pro'
import FormImageThumbnail from './FormImageThumbnail'

// icons
import AddMoreIconGrey from '../../../assets/icons/AddMoreIconGrey.png'
import InformationIcon from '../../../assets/icons/InformationIcon.png'

// styling
import { styleAddImageVariant } from 'src/components/custom-styles/containerStyle'

// helpers
import { optionsMultiSelect } from 'src/helpers/format-options'
import { formatPriceNumber } from 'src/helpers/format-data'

const FormProductCombination = ({
  formProduct,
  editPage,
  variantList,
  discountTypeOptions,
  weightUnitOptions,
  combinationDetail,
  combinationIndex,
  handleAddCombinationList,
  handleRemoveCombinationList,
  handleVariantTypeProductCombinations,
  handleVariantOptionProductCombinations,
  handleVariantTypeOptionProductCombinations,
  handleWeightProductCombinations,
  handleWeightUnitProductCombinations,
  handleDiscountTypeProductCombinations,
  handleDiscountValueProductCombinations,
  handleStockProductCombinations,
  handleRemoveImageProductCombinations,
  handleImageProductCombinations,
  setOpenFilePreview,
  feedbackInvalid,
  invalid,
  isProductCombinationDiscountInvalid,
}) => {
  const currency = useSelector((state) => state.currency)

  const [showMultiSelect, setShowMultiSelect] = useState(true)

  useEffect(() => {
    if (combinationDetail) {
      setShowMultiSelect(false)
      setTimeout(() => [
        setShowMultiSelect(true)
      ], 1)
    }
  }, [combinationDetail])

  const price = combinationDetail?.combinations.map(combination => parseInt(combination?.variant_price)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const params = useParams()
  const { id } = params

  return (
    <div key={combinationIndex}>
      <CRow>
        <div className='mb-3 d-grid gap-2 d-md-flex justify-content-between align-items-center'>
          <CFormLabel>
            Select Combination Product Variant
          </CFormLabel>
        </div>
      </CRow>

      {combinationDetail?.combinations?.length > 0 && (
        <CRow className='mb-3 border-bottom'>
          {combinationDetail?.combinations?.map((list, index) => {
            const variantOptions = variantList?.find(item => item?.name?.toLowerCase() === list?.variant_type?.toLowerCase())?.options

            return (
              <CCol lg={6} className='mb-3'>
                <div className='fw-semibold mb-2'>
                  {variantList[index]?.name}
                </div>
                {showMultiSelect && (
                  <>
                    <CMultiSelect
                      placeholder={'Please select variant option...'}
                      options={list.variant_option && list.variant_option !== '' ? optionsMultiSelect(variantOptions, list.variant_option) : optionsMultiSelect(variantList[index]?.options)}
                      value={list.variant_option}
                      onChange={(value) => handleVariantOptionProductCombinations(value, index, combinationIndex)}
                      virtualScroller
                      visibleItems={5}
                      multiple={false}
                      selectionType="text"
                    />
                    <div className='mx-1 mt-2 fw-semibold' style={{ fontSize: ".875em" }}>
                      Product Variant Price
                    </div>
                    <div className='mx-1 mt-1' style={{ fontSize: ".875em" }}>
                      {formatPriceNumber(list.variant_price, currency?.code)}
                    </div>
                  </>
                )}
              </CCol>
            )
          })}
        </CRow>
      )}

      <CRow className='mb-3'>
        <CCol lg={6}>
          <CFormLabel>
            Price
          </CFormLabel>
          <CFormText className='mb-3'>
            {formatPriceNumber(price, currency?.code)}
          </CFormText>
          <CFormLabel>
            Quantity / Stock
            {
              id && combinationDetail.quantity < 5 && (
                <CTooltip
                  content="stock is below 5, please update your inventory soon"
                  placement="right"
                >
                  <CImage className='ms-2' src={InformationIcon} />
                </CTooltip>
              )
            }
          </CFormLabel>
          <CFormInput
            className='mb-3'
            placeholder='Input quantity / stock'
            type="text"
            value={combinationDetail.quantity}
            onChange={(e) => handleStockProductCombinations(e, combinationIndex)}
          />
          <CFormLabel>
            Weight
          </CFormLabel>
          <CInputGroup className='mb-3'>
            <CFormInput
              placeholder='Input weight'
              type="text"
              value={combinationDetail.weight}
              onChange={(e) => handleWeightProductCombinations(e, combinationIndex)}
            />
            <CInputGroupText>
              gr
            </CInputGroupText>
          </CInputGroup>
          <CFormLabel>
            Discount Type
          </CFormLabel>
          <CFormSelect
            className='mb-3'
            options={discountTypeOptions}
            onChange={(e) => handleDiscountTypeProductCombinations(e, combinationIndex)}
            value={combinationDetail.discount_type}
          />
          {combinationDetail.discount_type && (
            <>
              <CFormLabel>
                Product Discount Value
              </CFormLabel>
              {combinationDetail.discount_type === "fixed-amount" && (
                <CInputGroup>
                  <CInputGroupText>{currency?.code}</CInputGroupText>
                  <CFormInput
                    placeholder='Input combination discount value...'
                    type="text"
                    value={combinationDetail.discount_value}
                    onChange={(e) => handleDiscountValueProductCombinations(e, combinationIndex)}
                    invalid={isProductCombinationDiscountInvalid}
                    feedbackInvalid="Product discount value must be less than or equal to product price"
                  />
                </CInputGroup>
              )}
              {combinationDetail.discount_type === "percentage" && (
                <>
                  <CInputGroup>
                    <CFormInput
                      placeholder='Input combination discount value...'
                      type="text"
                      value={combinationDetail.discount_value}
                      onChange={(e) => handleDiscountValueProductCombinations(e, combinationIndex)}
                      invalid={isProductCombinationDiscountInvalid}
                    />
                    <CInputGroupText>%</CInputGroupText>
                  </CInputGroup>
                  {isProductCombinationDiscountInvalid && (
                    <div className='text-danger' style={{ fontSize: ".875em" }}>
                      Product discount value must be less than or equal to 100%
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </CCol>
        <CCol lg={6}>
          <div className='d-grid gap-2 d-md-flex align-items-center'>
            <CFormLabel >
              Product Combination Image
            </CFormLabel>
            <p
              style={{
                color: '#9E9E9E',
                fontSize: '16px',
                marginBottom: '.5rem'
              }}
            >
              (Optional)
            </p>
          </div>
          {combinationDetail?.images?.length > 0 ? (
            <FormImageThumbnail
              combinationIndex={combinationIndex}
              formProduct={formProduct}
              editPage={editPage}
              colSize='12'
              productImages={combinationDetail?.images}
              handleRemoveImage={handleRemoveImageProductCombinations}
              handleChangeImage={handleImageProductCombinations}
              setOpenFilePreview={setOpenFilePreview}
            />
          ) : (
            <>
              <CFormInput
                id="upload_single_image_combination_product"
                className="position-absolute invisible"
                type="file"
                onChange={(e) => handleImageProductCombinations(e, combinationIndex)}
                multiple={false}
                accept='.jpeg, .png, .gif'
              />
              <label className="mb-5" htmlFor="upload_single_image_combination_product">
                <CImage
                  fluid
                  src={AddMoreIconGrey}
                  style={styleAddImageVariant}
                />
              </label>
            </>
          )}
        </CCol>
      </CRow>
    </div>
  )
}

export default FormProductCombination;
