import React from 'react'
import CouponMenuIcon from './assets/icons/CouponMenuIcon.png'
import ChatMenuIcon from './assets/icons/ChatMenuIcon.png'
import CustomersMenuIcon from './assets/icons/CustomersMenuIcon.png'
import DashboardMenuIcon from './assets/icons/DashboardMenuIcon.png'
import MyPageMenuIcon from './assets/icons/MyPageMenuIcon.png'
import OrdersMenuIcon from './assets/icons/OrdersMenuIcon.png'
import ProductsMenuIcon from './assets/icons/ProductsMenuIcon.png'
import ReportsMenuIcon from './assets/icons/ReportsMenuIcon.png'
import { CImage, CNavGroup, CNavItem } from '@coreui/react-pro'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CImage fluid src={DashboardMenuIcon} className='me-2' />,
  },
  {
    component: CNavItem,
    name: 'Coupons',
    to: '/coupons/list',
    icon: <CImage fluid src={CouponMenuIcon} className='me-2' />,
  },
  {
    component: CNavItem,
    name: 'My Page',
    to: '/my-page',
    icon: <CImage fluid src={MyPageMenuIcon} className='me-2' />,
  },
  {
    component: CNavItem,
    name: 'Orders',
    to: '/orders/list',
    icon: <CImage fluid src={OrdersMenuIcon} className='me-2' />,
  },
  {
    component: CNavGroup,
    name: 'Products',
    icon: <CImage fluid src={ProductsMenuIcon} className='me-2' />,
    items: [
      {
        component: CNavItem,
        name: 'Product List',
        to: '/products/list',
      },
      // {
      //   component: CNavItem,
      //   name: 'Variants',
      //   to: '/products/variant/list',
      // },
      {
        component: CNavItem,
        name: 'Category',
        to: '/products/category/list',
      },
      {
        component: CNavItem,
        name: 'Sub Category',
        to: '/products/sub-category/list',
      },
      {
        component: CNavItem,
        name: 'Brands',
        to: '/products/brand/list',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/customers/list',
    icon: <CImage fluid src={CustomersMenuIcon} className='me-2' />,
  },
  {
    component: CNavItem,
    name: 'Chat',
    to: '/chat',
    icon: <CImage fluid src={ChatMenuIcon} className='me-2' />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Reports',
  //   to: '/reports',
  //   icon: <CImage fluid src={ReportsMenuIcon} className='me-2' />,
  // },
]

export default _nav
