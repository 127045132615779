import React from 'react'

// components
import { CButton, CSpinner } from '@coreui/react-pro'

// styling
import { styleButtonVars4 } from 'src/components/custom-styles/buttonStyles'

function ActionButtonFormSecond({
  isLoading,
  disabled,
  onCancel,
  onSave
}) {
  return (
    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton onClick={onCancel} style={styleButtonVars4}>
        Cancel
      </CButton>
      <CButton disabled={disabled} onClick={onSave} style={styleButtonVars4}>
        {isLoading && (
          <CSpinner className='me-2' component="span" color="light" size="sm" aria-hidden="true" />
        )}
        Save
      </CButton>
    </div>
  )
}

export default ActionButtonFormSecond