function getServerURL() {
  const localhost = 'http://localhost:3123'
  const staging = 'https://ecomm2-api.cloudxier.dev'
  // const production = 'https://www.vgjscn.com' || "https://vgjscn.com"

  let host = window.location.host;
  if (host) {
    host = host.toLowerCase();
  }

  let env
  switch (host) {
    case 'ecomm2-admin-stg.cloudxier.dev':
      env = staging
      break;
    case 'https://ecomm2-admin-stg.cloudxier.dev':
      env = staging
      break;
    // case 'admin.vgjscn.com':
    //   env = production
    //   break;
    // case 'https://admin.vgjscn.com':
    //   env = production
    //   break;
    default:
      env = localhost
  }
  return env
};

export default getServerURL
