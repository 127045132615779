import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppUpperDetails from 'src/components/details/my-page-details/AppUpperDetails'
import AppLowerDetails from 'src/components/details/my-page-details/AppLowerDetails'
import AppCardHeader from 'src/components/card/AppCardHeader'

// helpers
import Axios from 'src/helpers/axios'

const MyPage = () => {
  const navigate = useNavigate()

  const [userDetails, setUserDetails] = useState({})
  const [generalSettingDetails, setGeneralSettingDetails] = useState({})
  const [countryDetails, setCountryDetails] = useState({})
  const [subDistrictDetails, setSubDistrictDetails] = useState({})
  const [currencyDetails, setCurrencyDetails] = useState({})

  const handleCardHeaderButton = () => {
    navigate(`/my-page/edit/general-settings`)
    window.location.reload();
  }

  useEffect(() => {
    fetchProfileUser()
    fetchGeneralSettingsUser()
  }, []);

  useEffect(() => {
    if (generalSettingDetails && generalSettingDetails.id) {
      fetchCountryDetails(generalSettingDetails.country_id)
      fetchSubDistrictDetails(generalSettingDetails.subdistrict_id)
      fetchCurrencyDetails(generalSettingDetails.shop_currency_id)
    }
  }, [generalSettingDetails]);

  const fetchProfileUser = () => {
    Axios({
      method: "get",
      url: `/api/sso/v1/users/profile`,
    })
      .then(({ data }) => {
        setUserDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchGeneralSettingsUser = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/settings`,
    })
      .then(({ data }) => {
        setGeneralSettingDetails(data[0])
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchCountryDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/countries`,
    })
      .then(({ data }) => {
        const selectedCountry = data?.filter((country) => { return country?.id == id })
        setCountryDetails(selectedCountry[0])
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchSubDistrictDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/countries/subdistricts/details/${id}`,
    })
      .then(({ data }) => {
        setSubDistrictDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchCurrencyDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/currencies`,
    })
      .then(({ data }) => {
        const selectedCurrency = data?.filter((currency) => { return currency?.id == id })
        setCurrencyDetails(selectedCurrency[0])
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={<h4>My Page</h4>}
      bodyContent={
        <AppUpperDetails
          userDetails={userDetails}
        />
      }
      lowerBodyContent={
        <AppCard
          className='mt-3 mb-3'
          headerTitle={
            <AppCardHeader
              headerTitle={<h5>General Settings</h5>}
              headerButton="Edit"
              handleCardHeaderButton={handleCardHeaderButton}
            />
          }
          bodyContent={
            <AppLowerDetails
              generalSettingDetails={generalSettingDetails}
              countryDetails={countryDetails}
              subDistrictDetails={subDistrictDetails}
              currencyDetails={currencyDetails}
            />
          }
        />
      }
    />
  )
}

export default MyPage
