import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CCol,
  CImage,
  CBadge,
  CFormText,
  CFormLabel
} from '@coreui/react-pro'
import AppCard from 'src/components/AppCard'

// icons
import WishlistIcon from 'src/assets/icons/WishlistIcon.png'
import { formatPriceNumber } from 'src/helpers/format-data'

const CustomerWishlist = ({
  customerDetails,
  customerWishlist
}) => {
  const navigate = useNavigate()
  const currency = useSelector((state) => state.currency)

  return (
    <>
      {customerWishlist?.length > 0 ? (
        <>
          <h5 className="mt-3">{`${customerWishlist?.length} items in Wishlist`}</h5>
          <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 2 }} md={{ cols: 3 }}>
            {customerWishlist?.map((item, index) => {
              return (
                <CCol xs onClick={() => navigate(`/products/details/${item?.product?.id}`)} style={{ cursor: "pointer" }}>
                  <AppCard
                    className="mt-2"
                    headerTitle={<h5>{`Saved on ${moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}`}</h5>}
                    bodyContent={
                      <CRow>
                        <CCol lg='3'>
                          <CImage className='border p-1' style={{ width: "100%", aspectRatio: "1/1", objectFit: "contain" }} rounded src={item?.product?.images[0]?.image_url} />
                        </CCol>
                        <CCol lg='6'>
                          <CFormLabel>{item?.product?.name}</CFormLabel>
                          <CFormText>{formatPriceNumber(item?.product?.price || 0, currency?.code)}</CFormText>
                        </CCol>
                      </CRow>
                    }
                    key={index}
                  />
                </CCol>
              )
            })}
          </CRow>
        </>
      ) : (
        <div className='my-5 text-center'>
          <CImage className='mb-3' fluid src={WishlistIcon} />
          <h5><b>No Wishlist Saved Yet</b></h5>
        </div>
      )}
    </>
  )
}

export default CustomerWishlist
