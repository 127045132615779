
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormTextarea
} from '@coreui/react-pro'

const FormTextAreaInput = ({
  id,
  name,
  colSize,
  inputLabel,
  className,
  placeholder,
  rows,
  value,
  handleTextAreaInput,
  feedbackInvalid,
  invalid,
  textDescription
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      <CFormTextarea
        id={id}
        name={name}
        value={value}
        onChange={(e) => handleTextAreaInput(e)}
        rows={rows}
        placeholder={placeholder}
        feedbackInvalid={feedbackInvalid}
        invalid={invalid}
        text={textDescription}
      />
    </CCol>
  )
}

export default FormTextAreaInput