
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormInput,
  CBadge
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilXCircle } from '@coreui/icons'

// styling
import { styleInputVariantInfo1 } from 'src/components/custom-styles/inputStyles'

const FormTags = ({
  id,
  name,
  colSize,
  inputLabel,
  placeholder,
  className,
  tags,
  value,
  setSelectedTag,
  handleAddTags,
  handleRemoveTags,
  feedbackInvalid,
  invalid,
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <div className="mb-3">
        <CFormLabel>
          {inputLabel}
        </CFormLabel>
        <CFormInput
          id={id}
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={({ target: { value } }) => setSelectedTag(value)}
          onKeyDown={handleAddTags}
          invalid={invalid}
          feedbackInvalid={feedbackInvalid}
        />
        <p className='mt-2' style={styleInputVariantInfo1}>
          Click <b>'Enter'</b> to Add or <b>'Esc'</b> to Cancel
        </p>
        <div className="mt-3 mb-3">
          <CFormLabel className="d-flex gap-2 align-items-center">
            Selected Tags
          </CFormLabel>
          {tags?.length > 0 && (
            <div className="justify-content-evenly mt-2">
              {tags?.map((item, index) => (
                <CBadge
                  color="secondary"
                  className="me-2"
                  style={{
                    padding: "8px 12px",
                    fontSize: "16px",
                  }}
                  key={index}
                >
                  {item}
                  <CIcon
                    icon={cilXCircle}
                    style={{
                      cursor: "pointer"
                    }}
                    className="ms-2"
                    onClick={() => handleRemoveTags(item)}
                  />
                </CBadge>
              ))}
            </div>
          )}
        </div>
      </div>
    </CCol>
  )
}

export default FormTags