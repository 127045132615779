import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { CButton } from '@coreui/react-pro'

function FormImageCrop({ image, originalImage, handleCropImage }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    return (
        <div className='d-flex flex-column align-items-center'>
            <div
                style={{
                    maxHeight: '15rem',
                    aspectRatio: "1/1",
                    objectFit: "contain",
                    position: 'relative',
                    width: '100%',
                }}
            >
                <Cropper
                    image={image}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <CButton
                className='btn-sm'
                onClick={() => handleCropImage(image, originalImage, croppedAreaPixels, rotation)}
            >
                Done Crop
            </CButton>
        </div>
    )
}

export default FormImageCrop