export const productStatus = (status) => {
  switch (status) {
    case 'active':
      return 'success'
    case 'low in stock':
      return 'warning'
    case 'out of stock':
      return 'danger'
    case 'draft':
      return 'secondary'
    default:
      return 'secondary'
  }
}

export const orderStatus = (status) => {
  switch (status) {
    case 'success':
      return 'success'
    case 'completed':
      return 'success'
    case 'shipped':
      return 'info'
    case 'processing':
      return 'warning'
    case 'pending':
      return 'secondary'
    default:
      return 'secondary'
  }
}
