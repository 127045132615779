import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
} from '@coreui/react-pro'

const ReportsDetail = () => {
  const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

  return (
    <CCard className="mb-4">
      <CCardHeader>Report Detail</CCardHeader>
    </CCard>
  )
}

export default ReportsDetail
