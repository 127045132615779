import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CCol,
  CImage,
  CBadge,
  CFormText,
  CFormLabel
} from '@coreui/react-pro'
import AppCard from 'src/components/AppCard'

// icons
import CartIcons from 'src/assets/icons/CartIcons.png'
import Shirt1 from 'src/assets/images/Shirt1.png'
import { formatPriceNumber } from 'src/helpers/format-data'

const AppVariantDetails = ({
  productVariants,
}) => {
  const navigate = useNavigate()
  const currency = useSelector((state) => state.currency)

  return (
    <div>
      {productVariants?.length > 0 ? (
        <div>
          <h5 className="mt-2" style={{ color: "#8A93A2" }}>{`${productVariants?.length} Product Variants`}</h5>
          <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 3 }} md={{ cols: 3 }}>
            {productVariants?.map((item, index) => {
              return (
                <CCol xs>
                  <AppCard
                    headerTitle={<h5>{`Variant ${index + 1}`}</h5>}
                    bodyContent={
                      <div key={index}>
                        <CRow>
                          <CFormLabel>{item?.name}</CFormLabel>
                        </CRow>
                        {item?.variant_options?.length > 0 && (
                          <CRow>
                            {item?.variant_options?.map((option, index) => {
                              return (
                                <CCol lg='6'>
                                  {option?.image_url && (
                                    <CImage className='border p-1' style={{ width: "100%", aspectRatio: "1/1", objectFit: "contain" }} rounded src={option?.image_url} />
                                  )}
                                  <CFormLabel className="mt-2">{option?.name}</CFormLabel>
                                  <CFormText>{option?.variant_sku}</CFormText>
                                  <CFormText>{formatPriceNumber(option?.price, currency?.code)}</CFormText>
                                </CCol>
                              )
                            })}
                          </CRow>
                        )}
                      </div>
                    }
                    key={index}
                  />
                </CCol>
              )
            })}
          </CRow>
        </div>
      ) : (
        <div className='my-5 text-center'>
          {/* <CImage className='mb-3' fluid src={CartIcons} /> */}
          <h5><b>There is no variant in this product yet</b></h5>
        </div>
      )}
    </div>
  )
}

export default AppVariantDetails
