import React from 'react'

// components
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFooter
} from '@coreui/react-pro'

const AppCard = ({
  className,
  headerTitle,
  bodyContent,
  lowerBodyContent,
  footerContent,
  key
}) => {
  return (
    <CCard className={className} key={key}>
      <CCardHeader className='app-header-title' component='h5'>
        {headerTitle}
      </CCardHeader>
      <CCardBody>
        {bodyContent}
        {lowerBodyContent}
      </CCardBody>
      {footerContent && (
        <CFooter className="d-grid gap-2 d-md-flex justify-content-md-end">
          {footerContent}
        </CFooter>
      )}
    </CCard>
  )
}

export default AppCard
