import React, { useState } from 'react'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CBadge
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

// helpers
import { productStatus } from 'src/helpers/status-badge'
import { formatPriceNumber } from 'src/helpers/format-data'
import { capitalizeEachWord } from 'src/helpers/text-format'

const AppDetails = ({
  productDetails,
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <>
      <CRow className='mb-4'>
        <AppTextColumnDetails
          colSize="3"
          title="Product SKU"
          subtitle={productDetails?.sku}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Product Name"
          subtitle={productDetails?.name}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Sub Category Name"
          subtitle={productDetails?.sub_category?.name}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Category"
          subtitle={productDetails?.sub_category?.category?.name}
        />
      </CRow>
      <CRow className='mb-4'>
        <AppTextColumnDetails
          colSize="3"
          title="Price"
          subtitle={productDetails?.price ? formatPriceNumber(productDetails?.price, currency?.code) : "-"}
        />
        {(productDetails?.product_discounts?.[0]?.value_discount || productDetails?.product_discounts?.[0]?.percentage_discount) ? (
          <AppTextColumnDetails
            colSize="3"
            title="Discount"
            subtitle={productDetails?.product_discounts?.[0]?.value_discount ? formatPriceNumber(productDetails?.product_discounts?.[0]?.value_discount, currency?.code) : `${productDetails?.product_discounts?.[0]?.percentage_discount}%`}
          />
        ) : null}
        <AppTextColumnDetails
          colSize="3"
          title="Stock"
          subtitle={productDetails?.qty ? productDetails?.qty : `${productDetails?.combinations?.length} product combination(s)`}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Target Gender"
          subtitle={productDetails?.gender ? capitalizeEachWord(productDetails?.gender) : "-"}
        />
      </CRow>
      <CRow className='mb-4'>
        <AppTextColumnDetails
          colSize="3"
          title="Status"
          subtitle={
            <CBadge
              className='table-badge-status-content'
              color={productStatus(productDetails?.status)}
              shape="rounded-pill"
            >
              {productDetails?.status?.toString().toUpperCase()}
            </CBadge>
          }
        />
      </CRow>
      <CRow className='mb-4'>
        <AppImageDetail
          productDetailsPage
          colSize="12"
          title="Product Image"
          productImages={productDetails?.images}
        />
      </CRow>
    </>
  )
}

export default AppDetails
