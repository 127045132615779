import React, { useState, useEffect } from 'react'

// components
import { CButton, CImage } from '@coreui/react-pro'
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// icons
import AddMoreIconWhite from '../../assets/icons/AddMoreIconWhite.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

// helpers
import { columnCouponList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const CouponsList = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [couponList, setCouponList] = useState([]);
  const [couponListCount, setCouponListCount] = useState(0);
  const [couponListPerPage, setCouponListPerPage] = useState(10);
  const [activeCouponListPage, setActiveCouponListPage] = useState(1);
  const [searchCouponListFilter, setSearchCouponListFilter] = useState("");
  const [columnCouponListSorter, setColumnCouponListSorter] = useState({ column: "updatedAt", state: "desc" });

  useEffect(() => {
    let queries = {
      search: searchCouponListFilter,
      // limit: couponListPerPage,
      offset: couponListPerPage * activeCouponListPage - couponListPerPage,
      sort:
        (columnCouponListSorter &&
          `${columnCouponListSorter.column}%${columnCouponListSorter.state}`) ||
        "",
    };
    queries = formUrlQuery(queries);
    fetchAllCoupons(queries);
  }, [
    searchCouponListFilter,
    couponListPerPage,
    activeCouponListPage,
    columnCouponListSorter,
  ]);

  const fetchAllCoupons = (queries) => {
    Axios({
      method: "get",
      url: '/api/ecommerce/v1/promotions'
    })
      .then(({ data }) => {
        const tempCouponList = data.map((d) => ({
          ...d
        }));

        setCouponList(tempCouponList);
        if (activeCouponListPage === 1) {
          setCouponListCount(tempCouponList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/promotions/remove/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='coupon'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <CButton href='/coupons/add' style={styleButtonVars2}>
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          Add New Coupon
        </CButton>
      </div>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>List of Coupons</h4>}
        bodyContent={
          <AppTable
            couponListPage
            selectable
            tableFilter
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnCouponList}
            tableContent={couponList}
            contentPerPage={couponListPerPage}
            activeContentPage={activeCouponListPage}
            contentCount={couponListCount}
            searchContentFilter={searchCouponListFilter}
            setActiveContentPage={setActiveCouponListPage}
            setSearchContentFilter={setSearchCouponListFilter}
            setContentPerPage={setCouponListPerPage}
            setColumnContentSorter={setColumnCouponListSorter}
          />
        }
      />
    </>
  )
}

export default CouponsList
