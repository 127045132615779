import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

// components
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from '@coreui/react-pro';
import CustomerPurchaseHistory from './CustomerPurchaseHistory';
import CustomerWishlist from './CustomerWishlist'
import CustomerShoppingCart from './CustomerShoppingCart';

const AppSecondDetails = ({
  customerDetails,
  customerPurchaseHistory,
  customerWishlist,
  customerShoppingCart
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTabKey, setActiveTabKey] = useState(null);

  const tabs = [{
    id: 1,
    label: "Purchase History",
  }, {
    id: 2,
    label: "Wishlist",
  }, {
    id: 3,
    label: "Shopping Cart",
  }
  ];

  useEffect(() => {
    // const tabKey = parseInt(searchParams.get("tab"));
    if (!activeTabKey) {
      setActiveTabKey(1);
    }
    // else {
    //   setActiveTabKey(tabKey)
    // }
  }, []);

  const handleClicktab = (tabId) => {
    setActiveTabKey(tabId);
    // navigate(`/customers/details/list?tab=${tabId}`);
  };

  return (
    <>
      <CNav variant='underline'>
        {tabs.map((tab, index) => (
          <CNavItem key={index}>
            <CNavLink
              type="button"
              active={activeTabKey === tab.id}
              onClick={() => handleClicktab(tab.id)}
            >
              {tab.label}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>
      <CTabContent>
        <CTabPane visible={activeTabKey === 1}>
          <CustomerPurchaseHistory
            customerDetails={customerDetails}
            customerPurchaseHistory={customerPurchaseHistory}
          />
        </CTabPane>
        <CTabPane visible={activeTabKey === 2}>
          <CustomerWishlist
            customerDetails={customerDetails}
            customerWishlist={customerWishlist}
          />
        </CTabPane>
        <CTabPane visible={activeTabKey === 3}>
          <CustomerShoppingCart
            customerDetails={customerDetails}
            customerShoppingCart={customerShoppingCart}
          />
        </CTabPane>
      </CTabContent>
    </>
  )
}

export default AppSecondDetails
