import React, { useState } from 'react'

// components
import {
  CRow,
} from '@coreui/react-pro'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormImageUpload from '../general-input-form/FormImageUpload'
import FormSelectInput from '../general-input-form/FormSelectInput'
import FormImageThumbnail from '../general-input-form/FormImageThumbnail'

const AppForm = ({
  editPage,
  values,
  handleChange,
  errors,
  categoryOptions,
  handleRemoveImage,
  handleChangeImage,
  handleFileInput,
  handleFileOnDrop,
  setOpenFilePreview
}) => {

  return (
    <>
      <CRow className="mb-4">
        <FormTextInput
          id='sub_category_name'
          name='sub_category_name'
          colSize='6'
          inputLabel='Sub Category Name'
          placeholder='Enter sub category name...'
          value={values.sub_category_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.sub_category_name}
          invalid={errors.sub_category_name}
        />
        <FormSelectInput
          id='parent_category_id'
          name='parent_category_id'
          colSize='6'
          inputLabel='Parent Category'
          selectOptions={categoryOptions}
          value={values.parent_category_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.parent_category_id}
          invalid={errors.parent_category_id}
        />
      </CRow>
      <CRow className="mb-4">
        <FormTextAreaInput
          id='sub_category_desc'
          name='sub_category_desc'
          colSize='6'
          inputLabel='Sub Category Description'
          // textDescription='Maximum description text is 300 characters'
          placeholder='Enter sub category description...'
          rows='10'
          value={values.sub_category_desc}
          handleTextAreaInput={handleChange}
        />
        {values.sub_category_images.length > 0 ? (
          <FormImageThumbnail
            editPage={editPage}
            name='sub_category_images'
            inputLabel='Sub Category Image'
            colSize='6'
            productImages={values.sub_category_images}
            handleChangeImage={handleChangeImage}
            handleRemoveImage={handleRemoveImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            name='sub_category_images'
            colSize='6'
            inputLabel='Sub Category Image'
            handleFileInput={handleFileInput}
            handleFileOnDrop={handleFileOnDrop}
            multiple={false}
          />
        )}
      </CRow>
    </>
  )
}

export default AppForm
