import React from 'react'

// components
import {
  CButton,
  CFormInput,
  CRow
} from '@coreui/react-pro'
import AppModal from '../AppModal'

// icons
import CIcon from '@coreui/icons-react'
import { cilWarning } from '@coreui/icons'

// styling
import { styleButtonVars3 } from '../custom-styles/buttonStyles'

const AppModalConfirmation = ({
  confirmationType,
  itemName,
  itemDetails,
  itemType,
  showModal,
  setShowModal,
  handleConfirmation,
  values,
  errors,
  handleChange
}) => {

  return (
    <>
      {confirmationType === 'delete-item' && (
        <AppModal
          showModal={showModal}
          setShowModal={setShowModal}
          bodyTitleIcon={<CIcon icon={cilWarning} size='8xl' />}
          bodyTitle={`Are you sure you want to delete this ${itemName}?`}
          bodyDescription={`Once confirmed, it can not be undo and all ${itemName} details will be deleted permanently.`}
          modalFooter={
            <>
              <CButton size='lg' color="secondary" onClick={() => setShowModal({ show: false, item: {} })}>
                No
              </CButton>
              <CButton size='lg' style={styleButtonVars3} onClick={() => handleConfirmation(itemDetails, itemType)}>
                Yes
              </CButton>
            </>
          }
        />
      )}
      {confirmationType === 'shipping-order' && (
        <AppModal
          showModal={showModal}
          setShowModal={setShowModal}
          bodyTitleIcon={<CIcon icon={cilWarning} size='8xl' />}
          bodyTitle={`Are you sure you want to ship this ${itemName}?`}
          bodyDescription={
            <CRow className='align-items-center justify-content-center'>
              <p>
                Please input order shipping number below:
              </p>
              <CFormInput
                id='shipping_number'
                name='shipping_number'
                className='mb-2'
                style={{ width: '20rem' }}
                placeholder='Enter order shipping number...'
                type="text"
                value={values.shipping_number}
                onChange={(e) => handleChange(e)}
                feedbackInvalid={errors.shipping_number}
                invalid={errors.shipping_number}
              />
              <p>
                Once confirmed, it can not be undo and all {itemName} details will be updated permanently.
              </p>
            </CRow>
          }
          modalFooter={
            <>
              <CButton size='lg' color="secondary" onClick={() => setShowModal({ show: false, status: '' })}>
                Cancel
              </CButton>
              <CButton size='lg' style={styleButtonVars3} onClick={() => handleConfirmation()}>
                Submit
              </CButton>
            </>
          }
        />
      )}
      {confirmationType === 'completed-order' && (
        <AppModal
          showModal={showModal}
          setShowModal={setShowModal}
          bodyTitleIcon={<CIcon icon={cilWarning} size='8xl' />}
          bodyTitle={`Are you sure you want to complete this ${itemName}?`}
          bodyDescription={
            <CRow className='align-items-center justify-content-center'>
              <p>
                Please input order status below:
              </p>
              <CFormInput
                id='payment_status'
                name='payment_status'
                className='mb-2'
                style={{ width: '20rem' }}
                placeholder='Enter order status...'
                type="text"
                value={values.payment_status}
                onChange={(e) => handleChange(e)}
                feedbackInvalid={errors.payment_status}
                invalid={errors.payment_status}
              />
              <p>
                Once confirmed, it can not be undo and all {itemName} details will be updated permanently.
              </p>
            </CRow>
          }
          modalFooter={
            <>
              <CButton size='lg' color="secondary" onClick={() => setShowModal({ show: false, status: '' })}>
                Cancel
              </CButton>
              <CButton size='lg' style={styleButtonVars3} onClick={() => handleConfirmation()}>
                Submit
              </CButton>
            </>
          }
        />
      )}
    </>
  )
}

export default AppModalConfirmation
