import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormText,
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

const AppDetails = ({
  categoryDetails
}) => {

  return (
    <>
      <CRow>
        <CCol>
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Category Name"
            subtitle={categoryDetails?.name}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Category Description"
            subtitle={categoryDetails?.description}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Sub Category List"
            subtitle={`${categoryDetails?.sub_categories?.length} Items`}
          />
        </CCol>
        <CCol>
          <AppImageDetail
            colSize="12"
            className="mb-3"
            title="Category Image"
            productImages={categoryDetails?.image_url}
          />
        </CCol>
      </CRow>
    </>
  )
}

export default AppDetails
