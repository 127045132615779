import React from 'react'
import moment from 'moment'
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'

// components
import {
  CRow,
  CCol,
  CImage,
  CBadge,
  CFormText,
  CFormLabel
} from '@coreui/react-pro'
import AppCard from 'src/components/AppCard'

// icons
import PurchaseBasketIcon from 'src/assets/icons/PurchaseBasketIcon.png'

// helpers
import { orderStatus } from 'src/helpers/status-badge'
import { formatPriceNumber } from 'src/helpers/format-data'

const CustomerPurchaseHistory = ({
  customerDetails,
  customerPurchaseHistory
}) => {
  const currency = useSelector((state) => state.currency)
  const navigate = useNavigate()

  return (
    <>
      {customerPurchaseHistory?.length > 0 ? (
        <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 2 }} md={{ cols: 2 }}>
          {customerPurchaseHistory?.map((purchase, index) => {
            return (
              <CCol xs onClick={() => navigate(`/orders/details/${purchase?.id}`)} style={{ cursor: "pointer" }}>
                <AppCard
                  className="mt-2"
                  headerTitle={
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <b>{`${purchase?.transaction_id}`}</b> &nbsp; {moment(purchase?.transaction_date).format('DD/MM/YYYY HH:mm')}
                      </div>
                      <CBadge className='table-badge-status-content' color={orderStatus(purchase?.payment_status)} shape="rounded-pill">
                        {purchase?.payment_status?.toString().toUpperCase()}
                      </CBadge>
                    </div>
                  }
                  bodyContent={
                    <CRow >
                      {purchase?.products?.[0]?.product_thumbnail_url && (
                        <CCol lg='2'>
                          <CImage className='border p-1' style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover" }} rounded src={purchase?.products?.[0]?.product_thumbnail_url} />
                        </CCol>
                      )}
                      <CCol lg='4'>
                        <CFormLabel>{purchase?.products[0]?.product_name}</CFormLabel>
                        {purchase?.products?.length > 1 ? (
                          <CFormText>
                            <small>
                              {`+${purchase?.products?.length - 1} More Items`}
                            </small>
                          </CFormText>
                        ) : ""}
                      </CCol>
                      <CCol lg='4'>
                        <CFormLabel>Total Amount</CFormLabel>
                        <CFormText>{formatPriceNumber(purchase?.transaction_amount || 0, currency?.code)}</CFormText>
                      </CCol>
                    </CRow>
                  }
                  key={index}
                />
              </CCol>
            )
          })}
        </CRow>
      ) : (
        <div className='my-5 text-center'>
          <CImage className='mb-3' fluid src={PurchaseBasketIcon} />
          <h5><b>No Purchase History Yet</b></h5>
        </div>
      )}
    </>
  )
}

export default CustomerPurchaseHistory
