
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormText
} from '@coreui/react-pro'

const AppTextRowsDetails = ({
  colSize,
  className,
  title,
  subtitle,
  description
}) => {
  return (
    <>
      <CCol lg={colSize} className={`d-flex flex-row ${className}`}>
        <CFormLabel>
          {title}
          &emsp; : &ensp;
        </CFormLabel>
        <CFormText>
          {subtitle}
        </CFormText>
      </CCol>
      {description && (
        <CCol lg={colSize}>
          <CFormText>
            <i>{description}</i>
          </CFormText>
        </CCol>
      )}
    </>
  )
}

export default AppTextRowsDetails