
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormInput
} from '@coreui/react-pro'

const FormTextInput = ({
  id,
  name,
  colSize,
  className,
  inputLabel,
  placeholder,
  value,
  handleTextInput,
  feedbackInvalid,
  invalid,
  combinationIndex
}) => {
  return (
    <CCol lg={colSize} className={className}>
      {inputLabel && (
        <CFormLabel>
          {inputLabel}
        </CFormLabel>
      )}
      <CFormInput
        id={id}
        name={name}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={(e) => handleTextInput(e, combinationIndex)}
        feedbackInvalid={feedbackInvalid}
        invalid={invalid}
      />
    </CCol>
  )
}

export default FormTextInput
