import moment from 'moment'

export const arrayChartAxisData = (array, property) => {
  let newArray = [];

  array.forEach(element => {
    if (
      property === 'transaction_date' ||
      property === 'createdAt' ||
      property === 'updatedAt'
    ) {
      newArray.push(moment(element[property]).format('DD MMMM YYYY'));
    } else {
      newArray.push(element[property]);
    }
  });

  return newArray;
}

export const getMostPurchaseProductId = (array) => {
  let cartArray = [];

  array.forEach(element => {
    cartArray.push(element['cart_items']);
  });

  const newCartArray = cartArray.flat(1)

  let arrayProductId = []

  newCartArray.forEach(element => {
    arrayProductId.push(element['product_id']);
  });

  // get the  highest occurence
  if (arrayProductId.length == 0) return null;
  let modeMap = {};
  let maxEl = arrayProductId[0], maxCount = 1;
  for (let i = 0; i < arrayProductId.length; i++) {
    let el = arrayProductId[i];
    if (modeMap[el] == null)
      modeMap[el] = 1;
    else
      modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }

  return maxEl;
}

export const removeDuplicates = (array) => {
  const newArray = array.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.value === value.value && t.text === value.text
    ))
  )
  return newArray
}

export const formatPriceNumber = (number = 0, currency = "") => {
  return `${currency} ${new Intl.NumberFormat().format(number)}`
}
