import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormText,
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

const AppDetails = ({
  subCategoryDetails
}) => {

  return (
    <>
      <CRow>
        <CCol>
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Sub Category Name"
            subtitle={subCategoryDetails?.name}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Sub Category Description"
            subtitle={subCategoryDetails?.description}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Category"
            subtitle={subCategoryDetails?.category?.name}
          />
        </CCol>
        <CCol>
          <AppImageDetail
            colSize="12"
            className="mb-3"
            title="Sub Category Image"
            productImages={subCategoryDetails?.image_url}
          />
        </CCol>
      </CRow>
    </>
  )
}

export default AppDetails
