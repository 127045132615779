import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  user: {},
  generalSettings: {},
  currency: {},
  toastMessage: {
    isError: false,
    isShown: false,
    color: 'primary',
    text: ''
  }
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'SET_USER':
      return { ...state, ...rest }
    case 'SET_GENERAL_SETTING':
      return { ...state, ...rest }
    case 'SET_CURRENCY':
      return { ...state, ...rest }
    case 'SET_TOAST_MESSAGE':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
