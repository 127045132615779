import React from 'react'
import { useDispatch } from 'react-redux'

function useToastMessage() {
  const dispatch = useDispatch();

  const showSuccess = (message) => {
    dispatch({
      type: 'SET_TOAST_MESSAGE',
      toastMessage: {
        isError: false,
        isShown: true,
        color: 'success',
        text: message
      }
    })
  }

  const showError = (message) => {
    console.error(message)
    dispatch({
      type: 'SET_TOAST_MESSAGE',
      toastMessage: {
        isError: true,
        isShown: true,
        color: 'danger',
        text: message
      }
    })
  }

  return { showSuccess, showError };
}

export default useToastMessage
