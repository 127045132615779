import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CImage
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import * as icon from "@coreui/icons";
import FormTextInput from '../general-input-form/FormTextInput'
import FormSelectInput from '../general-input-form/FormSelectInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormPhoneNumber from '../general-input-form/FormPhoneNumber'
import FormImageUpload from '../general-input-form/FormImageUpload';

const AppForm = ({
  values,
  errors,
  handleChange,
  currencyOptions,
  countryOptions,
  provinceOptions,
  cityOptions,
  subDistrictOptions,
  phoneCountry,
  setPhoneCountry,
  setOpenFilePreview,
  handleFileInput,
  handleFileOnDrop,
}) => {
  return (
    <>
      <CRow className="mb-3">
        <CCol lg={4}>
          <FormTextInput
            className="mb-3"
            id='store_name'
            name='store_name'
            inputLabel='Store Name'
            placeholder='Enter store name...'
            value={values.store_name}
            handleTextInput={handleChange}
            feedbackInvalid={errors.store_name}
            invalid={errors.store_name}
          />
          <FormSelectInput
            id='store_currency_id'
            name='store_currency_id'
            inputLabel='Store Currency'
            selectOptions={currencyOptions}
            value={values.store_currency_id}
            handleSelectInput={handleChange}
            feedbackInvalid={errors.store_currency_id}
            invalid={errors.store_currency_id}
          />
        </CCol>
        <FormImageUpload
          colSize={4}
          inputLabel="Store Logo"
          id='store_logo'
          name='store_logo'
          handleFileInput={handleFileInput}
          handleFileOnDrop={handleFileOnDrop}
          invalid={errors?.store_logo}
          feedbackInvalid={errors?.store_logo}
        />
        <CCol>
          <div>
            <CFormLabel>
              Preview
            </CFormLabel>
          </div>
          <CImage
            fluid
            thumbnail
            rounded
            src={values?.store_logo?.[0] instanceof File ? URL.createObjectURL(values?.store_logo?.[0]) : values.store_logo}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <FormTextInput
          id='store_person_name'
          name='store_person_name'
          colSize='4'
          inputLabel='Store Contact Name'
          placeholder='Enter store contact name...'
          value={values.store_person_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.store_person_name}
          invalid={errors.store_person_name}
        />
        <FormTextInput
          id='store_person_email'
          name='store_person_email'
          colSize='4'
          inputLabel='Store Contact Email'
          placeholder='Enter store contact email...'
          value={values.store_person_email}
          handleTextInput={handleChange}
          feedbackInvalid={errors.store_person_email}
          invalid={errors.store_person_email}
        />
        <CCol lg={4} >
          <CFormLabel>
            Store Contact Number
          </CFormLabel>
          <CInputGroup>
            {phoneCountry && phoneCountry?.id && (
              <>
                <CInputGroupText>
                  {phoneCountry?.country_code && phoneCountry?.country_code !== '' ? (
                    <CIcon icon={icon[`cif${phoneCountry?.country_code}`]} />
                  ) : (
                    phoneCountry?.name
                  )}
                </CInputGroupText>
                <CInputGroupText>
                  {phoneCountry?.phone_code}
                </CInputGroupText>
              </>
            )}
            <CFormInput
              id='store_person_number'
              name='store_person_number'
              placeholder='Enter store contact number...'
              type='text'
              onChange={(e) => handleChange(e)}
              value={values.store_person_number}
              feedbackInvalid={errors.store_person_number}
              invalid={errors.store_person_number}
            />
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow>
        <CFormLabel>
          Store Location:
        </CFormLabel>
      </CRow>
      <CRow className="mb-3">
        <FormTextAreaInput
          id='store_address'
          name='store_address'
          colSize='4'
          inputLabel='Address'
          // textDescription='Maximum description text is 300 characters'
          placeholder='Enter store address...'
          rows='5'
          value={values.store_address}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.store_address}
          invalid={errors.store_address}
        />
        <FormSelectInput
          id='store_country_id'
          name='store_country_id'
          colSize='4'
          inputLabel='Country'
          selectOptions={countryOptions}
          value={values.store_country_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.store_country_id}
          invalid={errors.store_country_id}
        />
        <FormTextInput
          id='store_postal_code'
          name='store_postal_code'
          colSize='4'
          inputLabel='Postal Code'
          placeholder='Enter store postal code...'
          value={values.store_postal_code}
          handleTextInput={handleChange}
          feedbackInvalid={errors.store_postal_code}
          invalid={errors.store_postal_code}
        />
      </CRow>
      <CRow className="mb-3">
        <FormSelectInput
          id='store_province_id'
          name='store_province_id'
          colSize='4'
          inputLabel='Province'
          selectOptions={provinceOptions}
          value={values.store_province_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.store_province_id}
          invalid={errors.store_province_id}
          disabled={parseInt(values?.store_country_id) !== 104}
        />
        <FormSelectInput
          id='store_city_id'
          name='store_city_id'
          colSize='4'
          inputLabel='City'
          selectOptions={cityOptions}
          value={values.store_city_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.store_city_id}
          invalid={errors.store_city_id}
          disabled={parseInt(values?.store_country_id) !== 104}
        />
        <FormSelectInput
          id='store_subdistrict_id'
          name='store_subdistrict_id'
          colSize='4'
          inputLabel='Subdistrict'
          selectOptions={subDistrictOptions}
          value={values.store_subdistrict_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.store_subdistrict_id}
          invalid={errors.store_subdistrict_id}
          disabled={parseInt(values?.store_country_id) !== 104}
        />
      </CRow>
    </>
  )
}

export default AppForm
