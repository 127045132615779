import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CCol,
  CImage,
  CBadge,
  CFormText,
  CFormLabel
} from '@coreui/react-pro'
import AppCard from 'src/components/AppCard'

// icons
import CartIcons from 'src/assets/icons/CartIcons.png'
import { formatPriceNumber } from 'src/helpers/format-data'

const CustomerShoppingCart = ({
  customerDetails,
  customerShoppingCart
}) => {
  const navigate = useNavigate()
  const currency = useSelector((state) => state.currency)

  return (
    <>
      {customerShoppingCart?.length > 0 ? (
        <>
          <h5 className="mt-3">{`${customerShoppingCart?.length} items in Shopping Cart`}</h5>
          <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 2 }} md={{ cols: 3 }}>
            {customerShoppingCart?.map((item, index) => {
              return (
                <CCol xs onClick={() => navigate(`/products/details/${item?.product?.id}`)} style={{ cursor: "pointer" }}>
                  <AppCard
                    className="mt-2"
                    headerTitle={<h5>{`Added on ${moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}`}</h5>}
                    bodyContent={
                      <CRow>
                        <CCol lg='3'>
                          <CImage className='border p-1' style={{ width: "100%", aspectRatio: "1/1", objectFit: "contain" }} rounded src={item?.product?.images[0]?.image_url} />
                        </CCol>
                        <CCol lg='6'>
                          <CFormLabel>{`${item?.qty} x ${item?.product?.name}`}</CFormLabel>
                          <CFormLabel>
                            {item?.product_combination?.combination_options?.length > 0 && (
                              <>
                                {item?.product_combination?.combination_options?.map((item, index) => {
                                  return `${item?.variant?.name} ${item?.variant_option?.name} `
                                })}
                              </>
                            )}
                          </CFormLabel>
                          <CFormText>{formatPriceNumber(item?.product_unit_price || 0, currency?.code)}</CFormText>
                        </CCol>
                      </CRow>
                    }
                    key={index}
                  />
                </CCol>
              )
            })}
          </CRow>
        </>
      ) : (
        <div className='my-5 text-center'>
          <CImage className='mb-3' fluid src={CartIcons} />
          <h5><b>No Items In The Customer Cart Yet</b></h5>
        </div>
      )}
    </>
  )
}

export default CustomerShoppingCart
