import React, { useEffect, useState } from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormInput,
  CRow,
  CImage,
  CButton,
  CCard,
  CCardBody,
  CFormText,
} from '@coreui/react-pro'

// icons
import TrashIcon from '../../../assets/icons/TrashIcon.png'
import AddMoreIconGrey from '../../../assets/icons/AddMoreIconGrey.png'

// styling
import { styleButtonVars1 } from 'src/components/custom-styles/buttonStyles'
import { styleLabelVars1 } from 'src/components/custom-styles/labelStyles'
import FormImageCrop from './FormImageCrop'

const FormImageThumbnail = ({
  editPage,
  formProductImages,
  colSize,
  inputLabel,
  className,
  classNameRow,
  productImages,
  handleChangeImage,
  handleRemoveImage,
  setOpenFilePreview,
  setShowConfirmDelete,
  variantTypeIndex,
  variantOption,
  variantOptionIndex,
  combinationIndex,
  handleCropImage,
}) => {
  return (
    <CCol lg={colSize} className={className}>
      {inputLabel && (
        <CFormLabel>
          {inputLabel}
        </CFormLabel>
      )}

      {productImages?.length > 0 && (
        <CRow className={classNameRow}>
          <CFormText className='mb-2'>
            These images below show how your pictures will appear on the website
          </CFormText>
          {productImages.map((image, index) => {
            return (
              <CCard style={{ width: formProductImages ? '14rem' : '22rem', height: 'fit-content' }} className='mb-2 me-2' key={index}>
                {formProductImages ? (
                  <FormImageCrop
                    image={
                      image instanceof File ? URL.createObjectURL(image)
                        : image.image_url instanceof File ? URL.createObjectURL(image.image_url)
                          : image.image_url ? image.image_url
                            : image
                    }
                    originalImage={image}
                    handleCropImage={handleCropImage}
                  />
                ) : (
                  <CImage
                    rounded
                    align="center"
                    src={
                      image instanceof File ? URL.createObjectURL(image)
                        : image.image_url instanceof File ? URL.createObjectURL(image.image_url)
                          : image.image_url ? image.image_url
                            : image
                    }
                    className='mt-2'
                    style={{
                      maxHeight: formProductImages ? '8rem' : '15rem',
                      aspectRatio: "1/1",
                      objectFit: "contain"
                    }}
                    onClick={() => setOpenFilePreview(image)}
                  />
                )}
                <CCardBody>
                  <div className="d-grid gap-2 mx-auto text-center justify-content-center">
                    {editPage ? (
                      <>
                        <CFormInput
                          id={`upload_single_image_thumbnail_product-${index}`}
                          className="position-absolute invisible"
                          type="file"
                          onChange={(e) => handleChangeImage(e, index, variantTypeIndex, variantOption, variantOptionIndex, combinationIndex)}
                          multiple={false}
                          accept='.jpeg, .png, .gif'
                        />
                        <label htmlFor={`upload_single_image_thumbnail_product-${index}`} style={styleLabelVars1}>
                          <CImage fluid src={AddMoreIconGrey} height={15} width={15} className='me-2 mb-1' />
                          Replace Image
                        </label>
                        {formProductImages && (
                          <CButton
                            style={styleButtonVars1}
                            onClick={() => {
                              image.id && formProductImages ?
                                setShowConfirmDelete({ show: true, item: image, type: 'product-image' })
                                : handleRemoveImage(image, index, variantTypeIndex, variantOption, variantOptionIndex, combinationIndex)
                            }}
                          >
                            <CImage fluid src={TrashIcon} className='me-2 mb-1' />
                            Remove
                          </CButton>
                        )}
                      </>
                    ) : (
                      <CButton
                        style={styleButtonVars1}
                        onClick={() => handleRemoveImage(image, index, variantTypeIndex, variantOption, variantOptionIndex, combinationIndex)}
                      >
                        <CImage fluid src={TrashIcon} className='me-2 mb-1' />
                        Remove
                      </CButton>
                    )}
                  </div>
                </CCardBody>
              </CCard>
            )
          })}
        </CRow>
      )}
    </CCol>
  )
}

export default FormImageThumbnail
