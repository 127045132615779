import React from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel
} from '@coreui/react-pro'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormImageUpload from '../general-input-form/FormImageUpload'
import FormImageThumbnail from '../general-input-form/FormImageThumbnail'
import FormSelectInput from '../general-input-form/FormSelectInput'
import FormTags from '../general-input-form/FormTags'
import FormPhoneNumber from '../general-input-form/FormPhoneNumber'

const AppForm = ({
  editPage,
  values,
  handleChange,
  errors,
  industryOptions,
  countryOptions,
  country,
  setCountry,
  selectedTag,
  setSelectedTag,
  handleRemoveImage,
  handleChangeImage,
  handleFileInput,
  handleFileOnDrop,
  handleAddTags,
  handleRemoveTags,
  brandImages,
  setOpenFilePreview,
  brandDetails
}) => {

  return (
    <CRow>
      <CCol lg='6' className="mb-4">
        <FormTextInput
          id='brand_name'
          name='brand_name'
          colSize='12'
          inputLabel='Brand Name'
          placeholder='Enter brand name...'
          className='mb-3'
          value={values.brand_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.brand_name}
          invalid={errors.brand_name}
        />
        <FormTextInput
          id='brand_pic'
          name='brand_pic'
          colSize='12'
          inputLabel='Brand PIC'
          placeholder='Enter brand PIC name...'
          className='mb-3'
          value={values.brand_pic}
          handleTextInput={handleChange}
          feedbackInvalid={errors.brand_pic}
          invalid={errors.brand_pic}
        />
        <FormPhoneNumber
          colSize='12'
          inputLabel='Phone Number'
          placeholder='Enter phone number...'
          className='mb-3'
          countryOptions={countryOptions}
          country={country}
          setCountry={setCountry}
          value={values}
          handlePhoneNumber={handleChange}
          handlePhoneCountry={handleChange}
          errors={errors}
        />
        <FormSelectInput
          id='brand_industry'
          name='brand_industry'
          colSize='12'
          inputLabel='Industry'
          selectOptions={industryOptions}
          className='mb-3'
          value={values.brand_industry}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.brand_industry}
          invalid={errors.brand_industry}
        />
        {values.brand_industry == 1 && (
          <FormTextInput
            id='brand_other_industry'
            name='brand_other_industry'
            colSize='12'
            placeholder='Enter other industry name...'
            className='mb-3'
            value={values.brand_other_industry}
            handleTextInput={handleChange}
            feedbackInvalid={errors.brand_other_industry}
            invalid={errors.brand_other_industry}
          />
        )}
        <FormTextAreaInput
          id='brand_desc'
          name='brand_desc'
          colSize='12'
          inputLabel='Brand Description'
          className='mb-3'
          // textDescription='Maximum description text is 300 characters'
          placeholder='Add brand description...'
          rows='10'
          value={values.brand_desc}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.brand_desc}
          invalid={errors.brand_desc}
        />
        <FormTags
          id='brand_tags'
          name='brand_tags'
          colSize='12'
          inputLabel='Tags'
          placeholder='Add tags name...'
          className='mb-3'
          tags={values.brand_tags}
          value={selectedTag}
          setSelectedTag={setSelectedTag}
          handleAddTags={handleAddTags}
          handleRemoveTags={handleRemoveTags}
          feedbackInvalid={errors.brand_tags}
          invalid={errors.brand_tags}
        />
      </CCol>
      <CCol lg='6' className="mb-4">
        {editPage && (
          <CFormLabel>
            Brand ID &emsp; : &ensp; {brandDetails.brand_uuid}
          </CFormLabel>
        )}
        {values.brand_images.length > 0 ? (
          <FormImageThumbnail
            editPage={editPage}
            inputLabel='Brand Image'
            name='brand_images'
            colSize='12'
            className='mt-3'
            productImages={values.brand_images}
            handleChangeImage={handleFileInput}
            handleRemoveImage={handleRemoveImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            name='brand_images'
            colSize='12'
            className='mt-3'
            inputLabel='Brand Image'
            handleFileInput={handleFileInput}
            handleFileOnDrop={handleFileOnDrop}
            multiple={false}
          />
        )}
        <FormTextAreaInput
          id='brand_info'
          name='brand_info'
          colSize='12'
          inputLabel='Brand Profile / Information'
          // textDescription='Maximum description text is 300 characters'
          placeholder='Enter other information...'
          className='mt-3'
          rows='10'
          value={values.brand_info}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.brand_info}
          invalid={errors.brand_info}
        />
      </CCol>
    </CRow>
  )
}

export default AppForm
