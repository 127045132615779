
import React from 'react'
import moment from 'moment'
import { useSelector } from "react-redux"

// components
import {
  CCol,
  CRow
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppTextRowsDetails from '../general-details/AppTextRowsDetails'
import AppImageDetail from '../general-details/AppImageDetails'
import AppTagsDetails from '../general-details/AppTagsDetails'
import { formatPriceNumber } from 'src/helpers/format-data'

const AppFirstDetails = ({
  brandDetails,
  brandImages
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <CRow>
      <CCol lg='6' className="mb-4">
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Brand Name"
          subtitle={brandDetails?.name}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Brand PIC"
          subtitle={brandDetails?.person_in_charge}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Phone Number"
          subtitle={`(${brandDetails?.country?.phone_code})${brandDetails?.phone_number}`}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Industry"
          subtitle={
            brandDetails?.industry?.name === 'Others' ?
              `Others - ${brandDetails?.other_industry}`
              : brandDetails?.industry?.name
          }
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3 text-justify"
          title="Brand Description"
          subtitle={brandDetails?.description}
        />
        <AppTagsDetails
          colSize="12"
          title="Tags"
          tags={brandDetails?.tags}
        />
      </CCol>
      <CCol lg='6' className="mb-4">
        <AppTextRowsDetails
          colSize="12"
          className="mb-3"
          title="Brand ID"
          subtitle={brandDetails?.brand_uuid}
        />
        <AppImageDetail
          colSize="12"
          className="mb-3"
          title="Brand Image"
          productImages={brandImages[0]}
        />
        <AppTextRowsDetails
          colSize="12"
          className="mb-3"
          title="Total Products"
          subtitle={`${brandDetails?.products?.length > 0 ? brandDetails?.products?.length : 0} Products`}
        />
        <AppTextRowsDetails
          colSize="12"
          className="mb-3"
          title="Date Registered"
          subtitle={moment(brandDetails?.createdAt).format('DD/MM/YYYY')}
        />
        <AppTextRowsDetails
          colSize="12"
          title="Total Revenue"
          subtitle={formatPriceNumber(brandDetails?.revenue?.amount, currency?.code)}
          description={`The total revenue you see includes the sales amount before tax of products that have transaction status marked as ${brandDetails?.revenue?.includedStatus?.join("/")}.`}
        />
      </CCol>
    </CRow>
  )
}

export default AppFirstDetails
