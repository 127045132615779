import React, { useEffect, useState } from 'react'
import moment from 'moment'

// components
import {
  CCol,
  CFormLabel,
  CFormText,
  CRow
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import { getFullName } from 'src/helpers/text-format'
import { getFullPhoneNumber } from 'src/helpers/text-format'

// helpers
import Axios from 'src/helpers/axios'

const AppFirstDetails = ({
  customerDetails,
  shippingAddressDetails
}) => {
  const [subDistrictDetails, setSubDistrictDetails] = useState({})

  useEffect(() => {
    if (shippingAddressDetails && shippingAddressDetails.subdistrict_id) {
      fetchSubDistrictDetails(shippingAddressDetails.subdistrict_id)
    }
  }, [shippingAddressDetails]);

  const fetchSubDistrictDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/countries/subdistricts/details/${id}`,
    })
      .then(({ data }) => {
        setSubDistrictDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const isAddressHasValue = shippingAddressDetails?.address_detail || subDistrictDetails?.subdistrict_name || subDistrictDetails?.city || subDistrictDetails?.province || shippingAddressDetails?.country?.name && shippingAddressDetails?.postal_code
  return (
    <CRow>
      <CCol lg='3'>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Customer ID"
            subtitle={customerDetails?.user_uuid ? customerDetails?.user_uuid : '-'}
          />
        </CRow>
        <CRow>
          <CFormLabel>
            Customer Name
          </CFormLabel>
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="First Name"
            subtitle={customerDetails?.profile?.first_name || shippingAddressDetails?.first_name || shippingAddressDetails?.contact_information_first_name || '-'}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Last Name"
            subtitle={customerDetails?.profile?.last_name || shippingAddressDetails?.last_name || shippingAddressDetails?.contact_information_last_name || '-'}
          />
        </CRow>
      </CCol>
      <CCol lg='3'>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Registered On"
            subtitle={moment(customerDetails?.createdAt).format('DD/MM/YYYY HH:mm')}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Gender"
            subtitle={customerDetails?.profile?.gender || '-'}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Email Address"
            subtitle={customerDetails?.email}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Phone Number"
            subtitle={getFullPhoneNumber(customerDetails?.profile)}
          />
        </CRow>
      </CCol>
      <CCol lg='3'>
        <CFormLabel>
          Address
        </CFormLabel>
        <CRow>
          <CCol lg='12' className='mb-3'>
            <CFormText className='customer-details-address-list'>
              {isAddressHasValue ? `${shippingAddressDetails?.address_detail || ""} ${subDistrictDetails?.subdistrict_name ? subDistrictDetails?.subdistrict_name + ", " : ""}${subDistrictDetails?.city ? subDistrictDetails?.city + ", " : ""}${subDistrictDetails?.province ? subDistrictDetails?.province + ", " : ""} ${shippingAddressDetails?.country?.name ? shippingAddressDetails?.country?.name + ", " : ""} ${shippingAddressDetails?.postal_code || ""}` : "-"}
              {isAddressHasValue && (
                <p className='customer-details-main-shipping-address'>{shippingAddressDetails?.address_name}</p>
              )}
            </CFormText>
            {/* <CFormText className='customer-details-address-list'>
              Paterson Road 2810 block 21 Sydney, NSW 2000 Australia
            </CFormText>
            <CFormText className='customer-details-address-list'>
              Paterson Road 2810 block 21 Sydney, NSW 2000 Australia
            </CFormText>
            <CFormText className="d-flex justify-content-between align-items-center">
              <p>+3 more address</p>
              <p>VIEW ALL</p>
            </CFormText> */}
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  )
}

export default AppFirstDetails
