export const styleButtonVars1 = {
  '--cui-btn-bg': '#000000',
  '--cui-btn-border-color': '#000000',
  '--cui-btn-active-bg': '#525252',
  '--cui-btn-active-border-color': '#525252',
  fontSize: '14px',
  width: '10.5rem'
}

export const styleButtonVars2 = {
  '--cui-btn-bg': '#000000',
  '--cui-btn-border-color': '#000000',
  '--cui-btn-active-bg': '#525252',
  '--cui-btn-active-border-color': '#525252'
}

export const styleButtonVars3 = {
  '--cui-btn-bg': '#000000',
  '--cui-btn-border-color': '#000000',
  '--cui-btn-active-bg': '#525252',
  '--cui-btn-active-border-color': '#525252',
  fontWeight: "500"
}

export const styleButtonVars4 = {
  '--cui-btn-bg': '#000000',
  '--cui-btn-border-color': '#000000',
  '--cui-btn-active-bg': '#525252',
  '--cui-btn-active-border-color': '#525252',
  fontWeight: "bold"
}

export const styleButtonVars5 = {
  '--cui-btn-bg': '#FFFFFF',
  '--cui-btn-border-color': '#FFFFFF',
  '--cui-btn-color': '#000000',
  '--cui-btn-active-bg': '#000000',
  '--cui-btn-active-border-color': '#000000'
}