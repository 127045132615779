import React, { useState } from 'react'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CMultiSelect
} from '@coreui/react-pro'

const FormDiscountValue = ({
  id,
  name,
  colSize,
  className,
  discountType,
  value,
  handleTextInput,
  feedbackInvalid,
  invalid,
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    discountType && discountType !== 'Please select discount type...' && (
      <CCol lg={colSize} className={className}>
        <CFormLabel>
          Product Discount Value
        </CFormLabel>
        {discountType === "fixed-amount" && (
          <CInputGroup>
            <CInputGroupText>{currency?.code}</CInputGroupText>
            <CFormInput
              id={id}
              name={name}
              placeholder='Enter product discount value...'
              type="text"
              value={value}
              onChange={(e) => handleTextInput(e)}
              feedbackInvalid={feedbackInvalid}
              invalid={invalid}
            />
          </CInputGroup>
        )}
        {discountType === "percentage" && (
          <>
            <CInputGroup>
              <CFormInput
                id={id}
                name={name}
                placeholder='Enter product discount value...'
                type="text"
                value={value}
                onChange={(e) => handleTextInput(e)}
                invalid={invalid}
              />
              <CInputGroupText>%</CInputGroupText>
            </CInputGroup>
            {feedbackInvalid && (
              <div className='text-danger' style={{ fontSize: ".875em" }}>{feedbackInvalid}</div>
            )}
          </>
        )}
      </CCol>
    )
  )
}

export default FormDiscountValue
