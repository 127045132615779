
import React from 'react'

// components
import {
  CInputGroup,
  CCol,
  CInputGroupText,
  CRow,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CFormText
} from '@coreui/react-pro'

const FormProductWeight = ({
  colSize,
  className,
  weightUnitOptions,
  values,
  errors,
  handleChange
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        Product Weight
      </CFormLabel>
      <CRow>
        <CInputGroup>
          <CFormInput
            id='product_weight'
            name='product_weight'
            placeholder={'Enter product weight...'}
            type="text"
            onChange={(e) => handleChange(e)}
            value={values.product_weight}
            className={errors?.product_weight ? "is-invalid" : ""}
          // feedbackInvalid={errors.product_weight}
          // invalid={errors.product_weight}
          />
          {/* <CFormSelect
            id='product_weight_unit'
            name='product_weight_unit'
            options={weightUnitOptions}
            onChange={(e) => handleChange(e)}
            value={values.product_weight_unit}
            feedbackInvalid={errors.product_weight_unit}
            invalid={errors.product_weight_unit}
          /> */}
          <CInputGroupText>
            gr
          </CInputGroupText>
        </CInputGroup>
      </CRow>
      {errors.product_weight && (
        <CFormText className='error-text-field'>
          {errors.product_weight}
        </CFormText>
      )}
    </CCol>
  )
}

export default FormProductWeight
