import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/add-brand/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'
import { singleOptionsSelect, singleCountriesOptionsSelect } from 'src/helpers/format-options'

const AddBrand = () => {
  const navigate = useNavigate()

  const [industryOptions, setIndustryOptions] = useState([])
  const [countryOptions, setCountryOptions] = useState('')
  const [country, setCountry] = useState({})
  const [selectedTag, setSelectedTag] = useState('')
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      brand_name: '',
      brand_pic: '',
      brand_images: [],
      phone_country: {},
      phone_number: '',
      brand_industry: '',
      brand_other_industry: '',
      brand_desc: '',
      brand_tags: [],
      brand_info: ''
    },
    validationSchema: Yup.object().shape({
      brand_name: Yup.string().required("Please enter brand name."),
      brand_pic: Yup.string().required("Please enter brand person name."),
      phone_country: Yup.string().required("Please enter brand contact number country."),
      phone_number: Yup.string().required("Please enter brand contact number."),
      brand_industry: Yup.string().required("Please select brand industry."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    fetchAllIndustries()
    fetchAllCountries()
  }, []);

  const fetchAllIndustries = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/industries/all",
    })
      .then(({ data }) => {
        setIndustryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllCountries = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/countries",
    })
      .then(({ data }) => {
        setCountryOptions(singleCountriesOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleInputValue = (values) => {
    const formData = new FormData()

    formData.append('name', values.brand_name)
    formData.append('person_in_charge', values.brand_pic)
    formData.append('phone_number', values.phone_number)
    formData.append('industry_id', values.brand_industry)
    formData.append('country_id', JSON.parse(values.phone_country).id)
    if (values.brand_images.length > 0) {
      formData.append('file', values.brand_images[0])
    }
    if (values.brand_desc !== '') {
      formData.append('description', values.brand_desc)
    }
    if (values.brand_tags !== '') {
      formData.append('tags', values.brand_tags)
    }
    if (values.brand_other_industry !== '') {
      formData.append('other_industry', values.brand_other_industry)
    }
    if (values.brand_info !== '') {
      formData.append('other_information', values.brand_info)
    }

    return formData
  }

  const handlePost = (payload) => {
    setIsLoading(true)
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/brands/products/create`,
      data: payload
    })
      .then(({ data }) => {
        navigate('/products/brand/list')
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const handleSave = (values) => {
    const inputPayload = handleInputValue(values)
    handlePost(inputPayload)
  }

  const handleRemoveImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      brand_images: values.brand_images.filter(image => image !== file)
    }), false)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        brand_images: attachments
      }), false)
    }
  }

  const handleImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        brand_images: attachments
      }), false)
    }
  }

  const handleAddTags = (e) => {
    if (e && e.key == "Enter") {
      if (selectedTag && selectedTag !== '') {
        formik.setValues((values) => ({
          ...values,
          brand_tags: [
            ...values.brand_tags,
            selectedTag
          ]
        }), false)
        setSelectedTag('')
      }
    }
  }

  const handleRemoveTags = (item) => {
    formik.setValues((values) => ({
      ...values,
      brand_tags: values.brand_tags.filter(tag => tag !== item)
    }), false)
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Add Brand</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            industryOptions={industryOptions}
            countryOptions={countryOptions}
            setCountry={setCountry}
            country={country}
            setSelectedTag={setSelectedTag}
            selectedTag={selectedTag}
            handleRemoveImage={handleRemoveImage}
            handleFileInput={handleImageAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            handleAddTags={handleAddTags}
            handleRemoveTags={handleRemoveTags}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/products/brand/list')}
            onSave={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default AddBrand
