import React, { useState } from 'react'
import moment from 'moment'

// components
import {
  CCol,
  CFormLabel,
  CDateRangePicker,
  CRow,
  CFormText
} from '@coreui/react-pro'

const FormDatePickerRange = ({
  editPage,
  couponDetails,
  id,
  name,
  colSize,
  className,
  inputLabel,
  startDate,
  endDate,
  handleStartDateRange,
  handleEndDateRange,
  feedbackInvalid,
  invalid,
  minDate,
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      {editPage && (
        <div className='mb-2'>
          <CRow>
            <CCol lg='3'>
              <CFormLabel>
                Start Date:
              </CFormLabel>
            </CCol>
            <CCol lg='3'>
              <CFormText>
                {couponDetails?.expired_start_date ? moment(couponDetails?.expired_start_date).format('DD/MM/YYYY') : "-"}
              </CFormText>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg='3'>
              <CFormLabel>
                End Date:
              </CFormLabel>
            </CCol>
            <CCol lg='3'>
              <CFormText>
                {couponDetails?.expired_end_date ? moment(couponDetails?.expired_end_date).format('DD/MM/YYYY') : "-"}
              </CFormText>
            </CCol>
          </CRow>
        </div>
      )}
      <CDateRangePicker
        id={id}
        name={name}
        className={feedbackInvalid ? 'date_range_picker_invalid' : ''}
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={(value) => handleStartDateRange(value)}
        onEndDateChange={(value) => handleEndDateRange(value)}
        feedbackInvalid={feedbackInvalid}
        invalid={invalid}
        minDate={minDate}
      />
      {feedbackInvalid && (
        <div className='invalid-feedback d-block'>
          {feedbackInvalid}
        </div>
      )}
    </CCol>
  )
}

export default FormDatePickerRange
