import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux"

// components
import { CButton, CImage } from '@coreui/react-pro'
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

// helpers\
import { columnBrandList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'
import useToastMessage from 'src/helpers/toast-message'
import { formatPriceNumber } from 'src/helpers/format-data'

const BrandList = () => {
  const currency = useSelector((state) => state.currency)
  const { showError } = useToastMessage();
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [brandList, setBrandList] = useState([]);
  const [brandListCount, setBrandListCount] = useState(0);
  const [brandListPerPage, setBrandListPerPage] = useState(10);
  const [activeBrandListPage, setActiveBrandListPage] = useState(1);
  const [searchBrandListFilter, setSearchBrandListFilter] = useState("");
  const [columnBrandListSorter, setColumnBrandListSorter] = useState({ column: "updatedAt", state: "desc" });

  useEffect(() => {
    let queries = {
      search: searchBrandListFilter,
      // limit: brandListPerPage,
      offset: brandListPerPage * activeBrandListPage - brandListPerPage,
      sort:
        (columnBrandListSorter &&
          `${columnBrandListSorter.column}%${columnBrandListSorter.state}`) ||
        "",
    };
    queries = formUrlQuery(queries);
    fetchAllBrands(queries);
  }, [
    searchBrandListFilter,
    brandListPerPage,
    activeBrandListPage,
    columnBrandListSorter,
  ]);

  const fetchAllBrands = (queries) => {
    Axios({
      method: "get",
      url: '/api/ecommerce/v1/brands/products/all/admin'
    })
      .then(({ data }) => {
        const tempBrandList = data.map((d) => ({
          ...d,
          total_products: d?.products?.length > 0 ? `${d?.products?.length} Items` : 'Not Available',
          total_sales: d?.revenue?.amount > 0 ? formatPriceNumber(d?.revenue?.amount, currency?.code) : "Not Available",
        }));

        setBrandList(tempBrandList);
        if (activeBrandListPage === 1) {
          setBrandListCount(tempBrandList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/brands/products/delete/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
        const message = err?.response?.data?.message?.replace(/-/g, " ").replace(err?.response?.data?.message.charAt(0), err?.response?.data?.message.charAt(0).toUpperCase())
        showError(message)
        setShowConfirmDelete({ show: false, item: {} })
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='brand'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <CButton href='/products/brand/add' style={styleButtonVars2}>
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          Add Brand
        </CButton>
      </div>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>Brand List</h4>}
        bodyContent={
          <AppTable
            brandListPage
            selectable
            tableFilter
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnBrandList}
            tableContent={brandList}
            contentPerPage={brandListPerPage}
            activeContentPage={activeBrandListPage}
            contentCount={brandListCount}
            searchContentFilter={searchBrandListFilter}
            setActiveContentPage={setActiveBrandListPage}
            setSearchContentFilter={setSearchBrandListFilter}
            setContentPerPage={setBrandListPerPage}
            setColumnContentSorter={setColumnBrandListSorter}
          />
        }
      />
    </>
  )
}

export default BrandList
