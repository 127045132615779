
import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormTextarea,
  CImage,
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CFormText
} from '@coreui/react-pro'

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'
import PencilIcon from '../../../assets/icons/PencilIcon.png'
import TrashIcon from '../../../assets/icons/TrashIcon.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

const FormProductInformation = ({
  editPage,
  colSize,
  inputLabel,
  className,
  placeholder,
  rows,
  productInformation,
  handleAddProductInformation,
  handleProductInfoTitle,
  handleProductInfoDesc,
  handleRemoveProductInformation,
  setShowConfirmDelete,
  errors,
  isProductInfoEmpty
}) => {

  return (
    <CCol lg={colSize} className={className}>
      <div className='mb-3 d-grid gap-2 d-md-flex justify-content-between align-items-center'>
        <CFormLabel>
          {inputLabel}
        </CFormLabel>
        <CButton
          onClick={() => handleAddProductInformation()}
          style={styleButtonVars2}
        >
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          {productInformation?.length > 0 ? 'Add More' : 'Add'}
        </CButton>
      </div>
      {errors.product_info && (
        <CFormText className='mb-3 error-text-field'>
          {errors.product_info}
        </CFormText>
      )}
      {isProductInfoEmpty && (
        <CFormText className='mb-3 error-text-field'>
          Please fill out all of the fields for product information.
        </CFormText>
      )}
      {productInformation?.length > 0 && (
        productInformation?.map((inputField, index) => {
          return (
            <CRow className='mb-3' key={index}>
              <CCol lg={12}>
                <div className='mb-2 d-grid gap-2 d-md-flex justify-content-between align-items-center'>
                  <CInputGroup>
                    <CFormInput
                      placeholder='Add field title...'
                      value={inputField.info_title}
                      onChange={(e) => handleProductInfoTitle(e, index)}
                    // feedbackInvalid={errors.product_info && errors.product_info[index].info_title}
                    // invalid={errors.product_info && errors.product_info[index].info_title}
                    />
                    {/* {!editPage && (
                      <CInputGroupText>
                        <CImage fluid src={PencilIcon} />
                      </CInputGroupText>
                    )} */}
                    <CInputGroupText>
                      <CImage
                        fluid
                        src={TrashIcon}
                        onClick={() => {
                          inputField.info_id ?
                            setShowConfirmDelete({ show: true, item: inputField, type: 'product-info' })
                            : handleRemoveProductInformation(index)
                        }}
                        style={{
                          cursor: 'pointer'
                        }}
                      />
                    </CInputGroupText>
                  </CInputGroup>
                  {/* <>
                    <CFormLabel>
                      Field Title
                      <CImage fluid src={PencilIcon} className='ms-2' />
                    </CFormLabel>
                  </>
                  <>
                    <CImage
                      fluid
                      src={TrashIcon}
                      onClick={() =>
                      } />
                  </> */}
                </div>
                <CFormTextarea
                  rows={rows}
                  placeholder={placeholder}
                  value={inputField.info_desc}
                  onChange={(e) => handleProductInfoDesc(e, index)}
                // feedbackInvalid={errors.product_info && errors.product_info[index].info_desc}
                // invalid={errors.product_info && errors.product_info[index].info_desc}
                />
              </CCol>
            </CRow>
          )
        })
      )}
    </CCol>
  )
}

export default FormProductInformation
