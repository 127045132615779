import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormCheck,
  CMultiSelect,
  CFormText,
  CImage,
  CTooltip
} from '@coreui/react-pro'
import Select from 'react-select'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormSelectInput from '../general-input-form/FormSelectInput'
import FormProductPrice from '../general-input-form/FormProductPrice'
import FormProductInformation from '../general-input-form/FormProductInformation'
import FormImageUpload from '../general-input-form/FormImageUpload'
import FormImageThumbnail from '../general-input-form/FormImageThumbnail'
import FormDiscountValue from '../general-input-form/FormDiscountValue'
import FormProductWeight from '../general-input-form/FormProductWeight'

// helpers
import { singleOptionsSelectNew } from 'src/helpers/format-options'

// Icons
import InformationIcon from '../../../assets/icons/InformationIcon.png'

const AppFirstForm = ({
  editPage,
  categoryOptions,
  subCategoryOptions,
  brandOptions,
  shippingOptions,
  discountTypeOptions,
  weightUnitOptions,
  genderOptions,
  isProductInfoEmpty,
  openFormProductVariant,
  setOpenFormProductVariant,
  openDisplaySizeGuide,
  setOpenDisplaySizeGuide,
  openFormProductCombination,
  setOpenFilePreview,
  setShowConfirmDelete,
  values,
  errors,
  handleChange,
  handleChangeImage,
  handleFileInput,
  handleFileOnDrop,
  handleMultiSelect,
  handleRemoveImage,
  handleAddProductInformation,
  handleRemoveProductInformation,
  handleProductInfoTitle,
  handleProductInfoDesc,
  handleCropImage,
}) => {
  const params = useParams()
  const { id } = params
  
  return (
    <>
      <CRow className="mb-4">
        <FormTextInput
          id='product_name'
          name='product_name'
          colSize='6'
          inputLabel='Product Name'
          placeholder='Enter product name...'
          value={values.product_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.product_name}
          invalid={errors.product_name}
        />
        <FormSelectInput
          id='category_id'
          name='category_id'
          colSize='3'
          inputLabel='Category'
          selectOptions={categoryOptions}
          value={values.category_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.category_id}
          invalid={errors.category_id}
        />
        <FormSelectInput
          id='subcategory_id'
          name='subcategory_id'
          colSize='3'
          inputLabel='Sub Category'
          selectOptions={subCategoryOptions}
          value={values.subcategory_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.subcategory_id}
          invalid={errors.subcategory_id}
        />
      </CRow>
      <CRow className="mb-4">
        <FormTextAreaInput
          id='product_desc'
          name='product_desc'
          colSize='6'
          inputLabel='Product Description'
          textDescription='Maximum description text is 200 characters'
          placeholder='Enter product description...'
          rows='3'
          value={values.product_desc}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.product_desc}
          invalid={errors.product_desc}
        />
        <FormSelectInput
          id='brand_id'
          name='brand_id'
          colSize='3'
          inputLabel='Brand'
          selectOptions={brandOptions}
          value={values.brand_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.brand_id}
          invalid={errors.brand_id}
        />
        {openFormProductCombination ? (
          null
        ) : (
          <FormTextInput
            id='product_quantity'
            name='product_quantity'
            colSize='3'
            inputLabel={
              id && values.product_quantity < 5 
              ? <span>Quantity / Stock <CTooltip content="stock is below 5, please update your inventory soon" placement="right"><CImage className='ms-2' src={InformationIcon} /></CTooltip></span> 
              : <span>Quantity / Stock</span>
            }
            placeholder='Input quantity / stock...'
            value={values.product_quantity}
            handleTextInput={handleChange}
            feedbackInvalid={errors.product_quantity}
            invalid={errors.product_quantity}
          />
        )}
      </CRow>
      <CRow className="mb-4">
        <FormProductPrice
          id='product_price'
          name='product_price'
          colSize='3'
          inputLabel='Product Price'
          placeholder='Enter product price...'
          value={values.product_price}
          handlePriceInput={handleChange}
          feedbackInvalid={errors.product_price}
          invalid={errors.product_price}
        />
        {openFormProductCombination ? (
          null
        ) : (
          <FormProductWeight
            colSize='3'
            weightUnitOptions={weightUnitOptions}
            values={values}
            errors={errors}
            handleChange={handleChange}
          />
        )}

        {/* <CCol lg={6}> */}
        {/* <CFormLabel>
            Shipping Method
          </CFormLabel> */}
        {/* <CMultiSelect
            id='product_shipping'
            name='product_shipping'
            placeholder='Please select shipping method options...'
            options={optionsMultiSelect(shippingOptions)}
            value={values.product_shipping}
            feedbackInvalid={errors.product_shipping}
            invalid={errors.product_shipping}
            onChange={(value) => handleMultiSelect(value)}
            selectionType="tags"
            virtualScroller
            visibleItems={5}
          /> */}
        {/* <Select
            isMulti
            id='product_shipping'
            name='product_shipping'
            placeholder='Please select shipping method options...'
            options={singleOptionsSelectNew(shippingOptions)}
            value={values.product_shipping}
            onChange={handleMultiSelect}
            className={errors?.product_shipping ? "product_shipping_error" : ""}
          />
          {errors.product_shipping && (
            <CFormText className='error-text-field'>
              {errors.product_shipping}
            </CFormText>
          )} */}
        {/* </CCol> */}
      </CRow>
      <CRow className="mb-4">
        <FormSelectInput
          id='product_discount_type'
          name='product_discount_type'
          colSize='3'
          inputLabel='Product Discount Type'
          selectOptions={discountTypeOptions}
          value={values.product_discount_type}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.product_discount_type}
          invalid={errors.product_discount_type}
        />
        {values.product_discount_type && (
          <FormDiscountValue
            id='product_discount_value'
            name='product_discount_value'
            colSize='3'
            discountType={values.product_discount_type}
            value={values.product_discount_value}
            handleTextInput={handleChange}
            handleShippingMethod={handleChange}
            feedbackInvalid={errors.product_discount_value}
            invalid={errors.product_discount_value}
          />
        )}
      </CRow>

      {/* <CRow className="mb-4">
        <CCol lg='12'>
          <CFormCheck
            className='me-2'
            label="Display product size guide"
            onClick={() => setOpenDisplaySizeGuide((prev) => !prev)}
            checked={openDisplaySizeGuide}
          />
        </CCol>
      </CRow> */}
      <CRow className='mb-4'>
        <FormSelectInput
          id='gender'
          name='gender'
          colSize='3'
          inputLabel='Target Gender'
          selectOptions={genderOptions}
          value={values.gender}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.gender}
          invalid={errors.gender}
        />

      </CRow>
      <CRow className="mb-4">
        <FormProductInformation
          id='product_info'
          name='product_info'
          editPage={editPage}
          colSize='6'
          inputLabel='Product Information'
          placeholder='Add information...'
          rows='3'
          setShowConfirmDelete={setShowConfirmDelete}
          productInformation={values.product_info}
          handleAddProductInformation={handleAddProductInformation}
          handleRemoveProductInformation={handleRemoveProductInformation}
          handleProductInfoTitle={handleProductInfoTitle}
          handleProductInfoDesc={handleProductInfoDesc}
          errors={errors}
          isProductInfoEmpty={isProductInfoEmpty}
        />
        {values.product_images.length > 0 ? (
          <FormImageThumbnail
            formProductImages
            editPage={editPage}
            id='product_images'
            name='product_images'
            colSize='6'
            inputLabel='Product Images'
            productImages={values.product_images}
            handleRemoveImage={handleRemoveImage}
            handleChangeImage={handleChangeImage}
            setOpenFilePreview={setOpenFilePreview}
            setShowConfirmDelete={setShowConfirmDelete}
            handleCropImage={handleCropImage}
          />
        ) : (
          <FormImageUpload
            formProductImages
            id='product_images'
            name='product_images'
            colSize='6'
            inputLabel='Product Image'
            handleFileInput={handleFileInput}
            handleFileOnDrop={handleFileOnDrop}
            multiple={true}
            errors={errors}
          />
        )}
      </CRow>
      <CRow className="mb-4">
        <CCol lg='6'>
          <CFormCheck
            className='me-2'
            label="Add product variant"
            onClick={() => setOpenFormProductVariant((prev) => !prev)}
            checked={openFormProductVariant}
          />
        </CCol>
        <CCol lg='6'>
          {null}
        </CCol>
      </CRow>
    </>
  )
}

export default AppFirstForm
