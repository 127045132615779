import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppFirstDetails from 'src/components/details/order-details/AppFirstDetails'
import AppSecondDetails from 'src/components/details/order-details/AppSecondDetails'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// helpers
import Axios from 'src/helpers/axios'

const OrderDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [orderDetails, setOrderDetails] = useState('')
  const [shippingAddressDetails, setShippingAddressDetails] = useState('')
  const [showConfirmOrderStatus, setShowConfirmOrderStatus] = useState({
    show: false,
    status: '',
  })

  const formik = useFormik({
    initialValues: {
      shipping_number: '',
    },
    validationSchema: Yup.object().shape({
      shipping_number: Yup.string().required("Please enter order shipping number.")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSubmit(values)
      setSubmitting(false)
    },
  });

  const formikCompleted = useFormik({
    initialValues: {
      payment_status: '',
    },
    validationSchema: Yup.object().shape({
      payment_status: Yup.string().required("Please enter order status.")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSubmit(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    fetchOrderDetails(id)
  }, [id]);

  useEffect(() => {
    if (orderDetails && orderDetails?.shipping_address_id) {
      fetchShippingAddressDetails(orderDetails?.shipping_address_id)
    }
  }, [orderDetails]);

  const fetchOrderDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/transactions/id/${id}`,
    })
      .then(({ data }) => {
        setOrderDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchShippingAddressDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/shippingaddress/id/${id}`,
    })
      .then(({ data }) => {
        setShippingAddressDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleUpdateOrderStatus = (payload) => {
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/transactions/shippingnumber/update/${id}`,
      data: payload
    })
      .then(({ data }) => {
        setShowConfirmOrderStatus({ show: false, status: '' })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const handleUpdateOrderPaymentStatus = (payload) => {
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/transactions/paymentstatus/update/${id}`,
      data: payload
    })
      .then(({ data }) => {
        setShowConfirmOrderStatus({ show: false, status: '' })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      })
  }


  const handleInputOrderStatus = (values) => {
    let objPayload = {}

    objPayload.shipping_number = values.shipping_number

    return objPayload;
  }

  const handleSubmit = (values) => {
    let inputPayload;
    if (values && values['payment_status']) {
      inputPayload = values
      handleUpdateOrderPaymentStatus(inputPayload)
    } else {
      inputPayload = handleInputOrderStatus(values)
      handleUpdateOrderStatus(inputPayload)
    }
  }

  return (
    <>
      {showConfirmOrderStatus.show && showConfirmOrderStatus.status === 'shipped' && (
        <AppModalConfirmation
          confirmationType='shipping-order'
          itemName='order'
          showModal={showConfirmOrderStatus}
          setShowModal={setShowConfirmOrderStatus}
          handleConfirmation={() => formik.handleSubmit()}
          values={formik.values}
          errors={formik.errors}
          handleChange={formik.handleChange}
        />
      )}
      {showConfirmOrderStatus.show && showConfirmOrderStatus.status === 'completed' && (
        <AppModalConfirmation
          confirmationType='completed-order'
          itemName='order'
          showModal={showConfirmOrderStatus}
          setShowModal={setShowConfirmOrderStatus}
          handleConfirmation={() => formikCompleted.handleSubmit()}
          values={formikCompleted.values}
          errors={formikCompleted.errors}
          handleChange={formikCompleted.handleChange}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Order Details</h4>}
        bodyContent={
          <AppFirstDetails
            orderDetails={orderDetails}
            shippingAddressDetails={shippingAddressDetails}
            setShowConfirmOrderStatus={setShowConfirmOrderStatus}
          />
        }
        lowerBodyContent={
          <AppSecondDetails
            orderDetails={orderDetails}
            shippingAddressDetails={shippingAddressDetails}
          />
        }
      />
    </>
  )
}

export default OrderDetails
