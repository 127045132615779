import React from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormText,
  CImage
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'
import AppCard from 'src/components/AppCard'

// icons
import WishlistIcon from 'src/assets/icons/WishlistIcon.png'
import Shirt1 from 'src/assets/images/Shirt1.png'

const AppSecondDetails = ({
  couponDetails
}) => {

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Apply Coupon To"
          subtitle={couponDetails?.is_all_products ? 'Entire Product' : 'Selected Product'}
        />
        {/* <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Uses Per Coupon"
          subtitle={'Unlimited'}
        // subtitle={couponDetails?.uses_per_coupon}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Uses Per Customer"
          subtitle={'1'}
        // subtitle={couponDetails?.uses_per_customer}
        /> */}
      </CRow>
      {!couponDetails?.is_all_products && (
        <CRow>
          {couponDetails?.product_promos?.length > 0 && (
            <CCol lg='12'>
              <CFormLabel>
                Products Eligibility
              </CFormLabel>
              <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 2 }} md={{ cols: 3 }}>
                {couponDetails?.product_promos?.map((item, index) => {
                  return (
                    <CCol xs onClick={() => navigate(`/product/details/${item?.id}`)}>
                      <AppCard
                        headerTitle={<h5>{item?.product?.sku}</h5>}
                        bodyContent={
                          <CRow>
                            <CCol lg='3'>
                              {
                                item?.product &&
                                item?.product?.images &&
                                item?.product?.images[0] &&
                                item?.product?.images[0]?.image_url && (
                                  <CImage fluid thumbnail rounded src={item?.product?.images[0]?.image_url} width={100} />
                                )
                              }
                            </CCol>
                            <CCol lg='6'>
                              <CFormLabel>{item?.product?.name}</CFormLabel>
                              <CFormText>{item?.product?.sub_category?.category?.name}</CFormText>
                            </CCol>
                          </CRow>
                        }
                        key={index}
                      />
                    </CCol>
                  )
                })}
              </CRow>
            </CCol>
          )}
          {couponDetails?.combination_promos?.length > 0 && (
            <CCol lg='12'>
              <CFormLabel>
                Product Combinations Eligibility
              </CFormLabel>
              <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 2 }} md={{ cols: 3 }}>
                {couponDetails?.combination_promos?.map((item, index) => {
                  return (
                    <CCol xs onClick={() => navigate(`/product/details/${item?.id}`)}>
                      <AppCard
                        headerTitle={<h5>{item?.combination?.combination_sku}</h5>}
                        bodyContent={
                          <CRow>
                            <CCol lg='3'>
                              {
                                item?.combination &&
                                item?.combination?.image_url && (
                                  <CImage fluid thumbnail rounded src={item?.combination?.image_url} width={100} />
                                )
                              }
                            </CCol>
                            <CCol lg='6'>
                              <CFormLabel>{item?.combination?.product?.name}</CFormLabel>
                              <CFormText>
                                {item?.combination?.combination_options?.length > 0 && (
                                  <>
                                    {item?.combination?.combination_options?.map((option, index) => {
                                      return ` ${option?.variant?.name}: ${option?.variant_option?.name},`
                                    })}
                                  </>
                                )}
                              </CFormText>
                              <CFormText>{item?.combination?.product?.sub_category?.category?.name}</CFormText>
                            </CCol>
                          </CRow>
                        }
                        key={index}
                      />
                    </CCol>
                  )
                })}
              </CRow>
            </CCol>
          )}
        </CRow>
      )}
    </>
  )
}

export default AppSecondDetails
