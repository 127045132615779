import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormCheck,
  CMultiSelect
} from '@coreui/react-pro'
import Select from 'react-select'

// helpers
import Axios from 'src/helpers/axios'
import { singleOptionsSelect } from 'src/helpers/format-options'

const FormCouponValue = ({
  id,
  name,
  colSize,
  className,
  couponType,
  value,
  handleTextInput,
  handleShippingMethod,
  handleVoucherValue,
  feedbackInvalid,
  invalid,
  values,
  errors
}) => {
  const currency = useSelector((state) => state.currency)

  const [shippingOptions, setShippingOptions] = useState([])
  const [voucherType, setVoucherType] = useState(values.coupon_voucher_percentage ? 'percentage' : 'fixed-amount')

  useEffect(() => {
    fetchAllShippingMethod()
  }, []);

  const fetchAllShippingMethod = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/shippingmethods/",
    })
      .then(({ data }) => {
        setShippingOptions(data)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CCol lg={colSize} className={className}>
      {couponType === "fixed-amount" && (
        <>
          <CFormLabel>
            Coupon Value
          </CFormLabel>
          <CInputGroup>
            <CInputGroupText>{currency?.code}</CInputGroupText>
            <CFormInput
              id={id}
              name={name}
              type="text"
              value={value}
              onChange={(e) => handleTextInput(e)}
              feedbackInvalid={feedbackInvalid}
              invalid={invalid}
            />
          </CInputGroup>
        </>
      )}
      {couponType === "percentage" && (
        <>
          <CFormLabel>
            Coupon Value
          </CFormLabel>
          <CInputGroup>
            <CFormInput
              id={id}
              name={name}
              type="text"
              value={value}
              onChange={(e) => handleTextInput(e)}
              invalid={invalid}
            />
            <CInputGroupText>%</CInputGroupText>
            <div className='invalid-feedback'>
              {feedbackInvalid}
            </div>
          </CInputGroup>
        </>
      )}
      {couponType === "voucher" && (
        <>
          <CFormCheck
            className='mb-3'
            label="Fixed Amount"
            type="radio"
            onChange={() => setVoucherType('fixed-amount')}
            checked={voucherType === 'fixed-amount' || values.coupon_voucher_value}
          />
          <CFormCheck
            className='mb-3'
            label="Percentage"
            type="radio"
            onChange={() => setVoucherType('percentage')}
            checked={voucherType === 'percentage' || values.coupon_voucher_percentage}
          />
          {voucherType === 'fixed-amount' && (
            <>
              <CFormLabel>
                Voucher Fixed Amount Value
              </CFormLabel>
              <CInputGroup>
                <CInputGroupText>{currency?.code}</CInputGroupText>
                <CFormInput
                  id={id}
                  name={name}
                  type="text"
                  value={values.coupon_voucher_value}
                  onChange={({ target: { value } }) => handleVoucherValue(value, voucherType)}
                  feedbackInvalid={feedbackInvalid}
                  invalid={invalid}
                />
              </CInputGroup>
            </>
          )}
          {voucherType === 'percentage' && (
            <>
              <CFormLabel>
                Voucher Percentage Value
              </CFormLabel>
              <CInputGroup>
                <CFormInput
                  id={id}
                  name={name}
                  type="text"
                  value={values.coupon_voucher_percentage}
                  onChange={({ target: { value } }) => handleVoucherValue(value, voucherType)}
                  feedbackInvalid={feedbackInvalid}
                  invalid={invalid}
                />
                <CInputGroupText>%</CInputGroupText>
              </CInputGroup>
            </>
          )}
        </>
      )}
      {couponType === "free-shipping" && (
        <>
          <CFormLabel>
            Select Shipping Methods
          </CFormLabel>
          {/* <CMultiSelect
            id={id}
            name={name}
            placeholder="Please select shipping method..."
            // options={value? removeDuplicates(value.concat(shippingOptions)) : shippingOptions}
            options={shippingOptions}
            value={value}
            feedbackInvalid={feedbackInvalid}
            invalid={invalid}
            onChange={(e) => handleShippingMethod(e)}
            selectionType="tags"
            virtualScroller
            visibleItems={5}
          /> */}
          <Select
            isMulti
            id={id}
            name={name}
            placeholder='Please select shipping method...'
            options={singleOptionsSelect(shippingOptions)}
            value={value}
            onChange={(value) => handleShippingMethod(value)}
          />
          {/* {invalid && (
            <CFormText className='error-text-field'>
              {feedbackInvalid}
            </CFormText>
          )} */}
        </>
      )}
    </CCol>
  )
}

export default FormCouponValue
