import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard.js'
import AppCardHeader from 'src/components/card/AppCardHeader'
import AppFirstDetails from 'src/components/details/brand-details/AppFirstDetails'
import AppSecondDetails from 'src/components/details/brand-details/AppSecondDetails'

// helpers
import Axios from 'src/helpers/axios'

const AppDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [brandDetails, setBrandDetails] = useState('')
  const [brandImages, setBrandImages] = useState([])

  const handleCardHeaderButton = () => {
    navigate(`/products/brand/edit/${id}`);
    window.location.reload();
  }

  useEffect(() => {
    fetchBrandDetails()
  }, [id]);

  const fetchBrandDetails = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/brands/products/id/${id}`,
    })
      .then(({ data }) => {
        setBrandDetails(data)
        if (data.image_url && data.image_url !== '') {
          setBrandImages([data.image_url])
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h4>Brand Details</h4>}
          headerButton="Edit"
          handleCardHeaderButton={handleCardHeaderButton}
        />
      }
      bodyContent={
        <AppFirstDetails
          brandDetails={brandDetails}
          brandImages={brandImages}
        />
      }
      lowerBodyContent={
        <AppSecondDetails
          brandProducts={brandDetails?.products}
        />
      }
    />
  )
}

export default AppDetails
