import React, { useState, useEffect } from 'react'

// components
import { CButton, CImage } from '@coreui/react-pro'
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

// helpers
import { columnCategoryList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'
import useToastMessage from 'src/helpers/toast-message'

const CategoryList = () => {
  const { showError } = useToastMessage();

  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [categoryList, setCategoryList] = useState([]);
  const [categoryListCount, setCategoryListCount] = useState(0);
  const [categoryListPerPage, setCategoryListPerPage] = useState(10);
  const [activeCategoryListPage, setActiveCategoryListPage] = useState(1);
  const [searchCategoryListFilter, setSearchCategoryListFilter] = useState("");
  const [columnCategoryListSorter, setColumnCategoryListSorter] = useState({ column: "updatedAt", state: "desc" });

  useEffect(() => {
    let queries = {
      search: searchCategoryListFilter,
      // limit: categoryListPerPage,
      offset: categoryListPerPage * activeCategoryListPage - categoryListPerPage,
      sort:
        (columnCategoryListSorter &&
          `${columnCategoryListSorter.column}%${columnCategoryListSorter.state}`) ||
        "",
    };
    queries = formUrlQuery(queries);
    fetchAllCategories(queries);
  }, [
    searchCategoryListFilter,
    categoryListPerPage,
    activeCategoryListPage,
    columnCategoryListSorter,
  ]);

  const fetchAllCategories = (queries) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/all?${queries}`,
    })
      .then(({ data }) => {
        const tempCategoryList = data.map((d) => ({
          ...d,
          sub_category_count: d?.sub_categories?.length > 0 ? `${d?.sub_categories?.length} Items` : 'Not Available'
        }));

        setCategoryList(tempCategoryList);
        if (activeCategoryListPage === 1) {
          setCategoryListCount(tempCategoryList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/categories/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
        const message = err?.response?.data?.message?.replace(/-/g, " ").replace(err?.response?.data?.message.charAt(0), err?.response?.data?.message.charAt(0).toUpperCase())
        showError(message)
        setShowConfirmDelete({ show: false, item: {} })
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='category'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <CButton href='/products/category/add' style={styleButtonVars2}>
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          Add Category
        </CButton>
      </div>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>Category List</h4>}
        bodyContent={
          <AppTable
            categoryListPage
            selectable
            tableFilter
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnCategoryList}
            tableContent={categoryList}
            contentPerPage={categoryListPerPage}
            activeContentPage={activeCategoryListPage}
            contentCount={categoryListCount}
            searchContentFilter={searchCategoryListFilter}
            setActiveContentPage={setActiveCategoryListPage}
            setSearchContentFilter={setSearchCategoryListFilter}
            setContentPerPage={setCategoryListPerPage}
            setColumnContentSorter={setColumnCategoryListSorter}
          />
        }
      />
    </>
  )
}

export default CategoryList
