
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CBadge
} from '@coreui/react-pro'

const AppTagsDetails = ({
  colSize,
  className,
  title,
  tags,
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {title}
      </CFormLabel>
      {tags?.length > 0 && (
        <div className="justify-content-evenly mt-2">
          {tags?.map((item, i) => (
            <CBadge
              color="secondary"
              className="me-2"
              style={{
                padding: "8px 12px",
                fontSize: "16px",
              }}
              key={i}
            >
              {item?.name}
            </CBadge>
          ))}
        </div>
      )}
    </CCol>
  )
}

export default AppTagsDetails