import React, { useState } from 'react'

// components
import {
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
} from '@coreui/react-pro'
import AppFirstDetails from './AppFirstDetails'
import AppSecondDetails from './AppSecondDetails'

const AppDetails = ({
  couponDetails,
}) => {

  return (
    <CAccordion activeItemKey={1}>
      <CAccordionItem itemKey={1}>
        <CAccordionHeader>
          <b>Basic Coupon Information</b>
        </CAccordionHeader>
        <CAccordionBody>
          <AppFirstDetails
            couponDetails={couponDetails}
          />
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={1} hidden={couponDetails?.promo_type && couponDetails?.promo_type === "free-shipping"}>
        <CAccordionHeader>
          <b>Coupon Eligibility</b>
        </CAccordionHeader>
        <CAccordionBody>
          <AppSecondDetails
            couponDetails={couponDetails}
          />
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
  )
}

export default AppDetails
