
import React, { useState } from 'react'

// components
import {
  CInputGroup,
  CCol,
  CInputGroupText,
  CDropdown,
  CDropdownToggle,
  CFormSelect,
  CFormLabel,
  CFormInput
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

const FormPhoneNumber = ({
  colSize,
  className,
  inputLabel,
  placeholder,
  countryOptions,
  value,
  handlePhoneNumber,
  handlePhoneCountry,
  errors,
  feedbackInvalid,
  invalid,
}) => {
  const [isOpenDropdownPhoneNumber, setIsOpenDropdownPhoneNumber] = useState(false)

  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      <CInputGroup>
        <CDropdown variant="input-group">
          <CDropdownToggle
            className={errors?.phone_country?.name ? "border border-danger" : ""}
            color="secondary"
            variant="outline"
            onClick={() =>
              setIsOpenDropdownPhoneNumber(!isOpenDropdownPhoneNumber)
            }
          >
            {Object.keys(value.phone_country).length > 0 ? (
              <>
                {JSON.parse(value.phone_country).icon && JSON.parse(value.phone_country).icon !== '' ? (
                  <CIcon icon={JSON.parse(value.phone_country).icon} />
                ) : (
                  JSON.parse(value.phone_country).name
                )}
              </>
            ) : (
              'Country'
            )}
          </CDropdownToggle>
        </CDropdown>
        {Object.keys(value.phone_country).length > 0 && (
          <CInputGroupText>{JSON.parse(value.phone_country).phone_code}</CInputGroupText>
        )}
        <CFormInput
          id="phone_number"
          name="phone_number"
          placeholder={placeholder}
          type='text'
          feedbackInvalid={errors.phone_number || errors?.phone_country?.name}
          invalid={errors.phone_number || errors?.phone_country?.name}
          onChange={(e) => handlePhoneNumber(e)}
          value={value.phone_number}
        />
      </CInputGroup>
      {isOpenDropdownPhoneNumber && (
        <CFormSelect
          id="phone_country"
          name="phone_country"
          className="mt-3"
          onChange={(e) => {
            handlePhoneCountry(e);
            setIsOpenDropdownPhoneNumber(false)
          }}
          value={value.phone_country}
          options={countryOptions}
        />
      )}
    </CCol>
  )
}

export default FormPhoneNumber