import React, { useState } from 'react'

// components
import AppFirstForm from './AppFirstForm'
import AppSecondForm from './AppSecondForm'
import AppThirdForm from './AppThirdForm'

const AppForm = ({
  editPage,
  values,
  errors,
  handleChange,
  setProductImages,
  categoryOptions,
  subCategoryOptions,
  brandOptions,
  shippingOptions,
  discountTypeOptions,
  weightUnitOptions,
  genderOptions,
  isProductInfoEmpty,
  isProductVariantEmpty,
  isProductCombinationEmpty,
  isProductCombinationDiscountInvalid,
  handleRemoveImage,
  handleFileInput,
  handleFileOnDrop,
  handleChangeImage,
  handleMultiSelect,
  setOpenFilePreview,
  setShowConfirmDelete,
  openFormProductVariant,
  setOpenFormProductVariant,
  openDisplaySizeGuide,
  setOpenDisplaySizeGuide,
  openFormProductCombination,
  setOpenFormProductCombination,
  setInputVariant,
  inputVariant,
  handleAddProductInformation,
  handleRemoveProductInformation,
  handleInputVariantType,
  handleAddVariantOptions,
  handleRemoveInputVariantType,
  handleRemoveVariantOptions,
  handleTextVariantOptions,
  handlePriceVariantOptions,
  handleImageVariantOptions,
  handleRemoveImageVariantOptions,
  handleAddCombinationList,
  handleRemoveCombinationList,
  handleAddProductCombination,
  handleRemoveProductCombination,
  handleVariantTypeProductCombinations,
  handleVariantOptionProductCombinations,
  handleStockProductCombinations,
  handleWeightProductCombinations,
  handleWeightUnitProductCombinations,
  handleDiscountTypeProductCombinations,
  handleDiscountValueProductCombinations,
  handleRemoveImageProductCombinations,
  handleImageProductCombinations,
  handleProductInfoTitle,
  handleProductInfoDesc,
  handleCropImage,
}) => {

  return (
    <>
      <AppFirstForm
        editPage={editPage}
        setProductImages={setProductImages}
        categoryOptions={categoryOptions}
        subCategoryOptions={subCategoryOptions}
        brandOptions={brandOptions}
        shippingOptions={shippingOptions}
        discountTypeOptions={discountTypeOptions}
        weightUnitOptions={weightUnitOptions}
        genderOptions={genderOptions}
        isProductInfoEmpty={isProductInfoEmpty}
        openFormProductVariant={openFormProductVariant}
        setOpenFormProductVariant={setOpenFormProductVariant}
        openDisplaySizeGuide={openDisplaySizeGuide}
        setOpenDisplaySizeGuide={setOpenDisplaySizeGuide}
        openFormProductCombination={openFormProductCombination}
        setOpenFormProductCombination={setOpenFormProductCombination}
        setOpenFilePreview={setOpenFilePreview}
        setShowConfirmDelete={setShowConfirmDelete}
        handleChange={handleChange}
        values={values}
        errors={errors}
        handleChangeImage={handleChangeImage}
        handleRemoveImage={handleRemoveImage}
        handleFileInput={handleFileInput}
        handleFileOnDrop={handleFileOnDrop}
        handleMultiSelect={handleMultiSelect}
        handleAddProductInformation={handleAddProductInformation}
        handleRemoveProductInformation={handleRemoveProductInformation}
        handleProductInfoTitle={handleProductInfoTitle}
        handleProductInfoDesc={handleProductInfoDesc}
        handleCropImage={handleCropImage}
      />
      {openFormProductVariant && (
        <AppSecondForm
          editPage={editPage}
          values={values}
          errors={errors}
          isProductVariantEmpty={isProductVariantEmpty}
          openFormProductCombination={openFormProductCombination}
          setOpenFormProductCombination={setOpenFormProductCombination}
          setOpenFilePreview={setOpenFilePreview}
          setShowConfirmDelete={setShowConfirmDelete}
          setInputVariant={setInputVariant}
          inputVariant={inputVariant}
          handleInputVariantType={handleInputVariantType}
          handleAddVariantOptions={handleAddVariantOptions}
          handleRemoveInputVariantType={handleRemoveInputVariantType}
          handleRemoveVariantOptions={handleRemoveVariantOptions}
          handleTextVariantOptions={handleTextVariantOptions}
          handlePriceVariantOptions={handlePriceVariantOptions}
          handleImageVariantOptions={handleImageVariantOptions}
          handleRemoveImageVariantOptions={handleRemoveImageVariantOptions}
        />
      )}
      {openFormProductCombination && values.product_variants.length > 0 && (
        <AppThirdForm
          editPage={editPage}
          values={values}
          errors={errors}
          isProductCombinationEmpty={isProductCombinationEmpty}
          isProductCombinationDiscountInvalid={isProductCombinationDiscountInvalid}
          discountTypeOptions={discountTypeOptions}
          weightUnitOptions={weightUnitOptions}
          setOpenFilePreview={setOpenFilePreview}
          setShowConfirmDelete={setShowConfirmDelete}
          handleAddProductCombination={handleAddProductCombination}
          handleRemoveProductCombination={handleRemoveProductCombination}
          handleAddCombinationList={handleAddCombinationList}
          handleRemoveCombinationList={handleRemoveCombinationList}
          handleVariantTypeProductCombinations={handleVariantTypeProductCombinations}
          handleVariantOptionProductCombinations={handleVariantOptionProductCombinations}
          handleStockProductCombinations={handleStockProductCombinations}
          handleWeightProductCombinations={handleWeightProductCombinations}
          handleWeightUnitProductCombinations={handleWeightUnitProductCombinations}
          handleDiscountTypeProductCombinations={handleDiscountTypeProductCombinations}
          handleDiscountValueProductCombinations={handleDiscountValueProductCombinations}
          handleRemoveImageProductCombinations={handleRemoveImageProductCombinations}
          handleImageProductCombinations={handleImageProductCombinations}
        />
      )}
    </>
  )
}

export default AppForm
