import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import AppForm from 'src/components/form/add-coupon/AppForm'
import AppCard from 'src/components/AppCard'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormFirst from 'src/components/form/general-input-form/ActionButtonFormFirst'

// helpers
import Axios from 'src/helpers/axios'
import useToastMessage from 'src/helpers/toast-message'
import moment from 'moment'

const AddCoupon = () => {
  const navigate = useNavigate()
  const { showError } = useToastMessage();

  const [couponTypeOptions, setCouponTypeOptions] = useState([{
    label: "Please select...",
    value: ""
  }, {
    label: "Fixed Amount",
    value: "fixed-amount"
  }, {
    label: "Percentage",
    value: "percentage"
  }])
  const [openCouponPeriod, setOpenCouponPeriod] = useState(false)
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoadingDraft, setIsLoadingDraft] = useState(false)
  const [isLoadingPublish, setIsLoadingPublish] = useState(false)

  const formik = useFormik({
    initialValues: {
      coupon_name: '',
      coupon_images: [],
      coupon_desc: '',
      // coupon_code: '',
      coupon_type: '',
      coupon_value: '',
      coupon_voucher_value: '',
      coupon_voucher_percentage: '',
      coupon_qty: '',
      coupon_is_active: false,
      coupon_valid_start: '',
      coupon_valid_end: '',
      apply_coupon_to: '',
      uses_per_coupon: '',
      uses_per_customer: '',
      coupon_list_products: [],
      coupon_list_product_combinations: [],
      coupon_list_categories: [],
      coupon_list_subcategories: [],
      coupon_list_brands: [],
    },
    validationSchema: Yup.object().shape({
      coupon_name: Yup.string().required("Please enter coupon name."),
      coupon_desc: Yup.string().required("Please enter coupon description."),
      // coupon_code: Yup.string().required("Please enter coupon code."),
      coupon_type: Yup.string().required("Please enter coupon type."),
      coupon_qty: Yup.string().required("Please enter coupon qty."),
      coupon_value: Yup.mixed()
        .when('coupon_type', {
          is: value => value === "fixed-amount" || value === "percentage",
          then: () => Yup.mixed().required("Please enter coupon value."),
        }),
      coupon_valid_start: Yup.date().min(moment().subtract(1, 'days'), "Coupon validity period must later than now").max(Yup.ref('coupon_valid_end'), "Coupon validity start date must be earlier than coupon end date"),
      coupon_valid_end: Yup.date().min(Yup.ref('coupon_valid_start'), "Coupon validity period must later than coupon validity start date"),
      apply_coupon_to: Yup.string().required("Please select the products to apply the coupon to."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handlePublish(values)
      setSubmitting(false)
    },
  });

  const handleInputValue = (values) => {
    const formData = new FormData()

    formData.append('title', values.coupon_name)
    formData.append('description', values.coupon_desc)
    // formData.append('promo_code', values.coupon_code)
    formData.append('quota', values.coupon_qty)
    formData.append('promo_type', values.coupon_type)
    formData.append('is_active', values.coupon_is_active)
    if (values.coupon_images.length > 0) {
      formData.append('file', values.coupon_images[0])
    }
    if (values.coupon_valid_start !== '' && values.coupon_valid_end !== '') {
      formData.append('expired_start_date', values.coupon_valid_start)
      formData.append('expired_end_date', values.coupon_valid_end)
    }
    if (values.coupon_type && values.coupon_type !== '' && values.coupon_type === 'fixed-amount') {
      formData.append('value_discount', values.coupon_value)
    }
    if (values.coupon_type && values.coupon_type !== '' && values.coupon_type === 'percentage') {
      formData.append('percentage_discount', values.coupon_value)
    }
    if (values.coupon_type && values.coupon_type !== '' && values.coupon_type === 'free-shipping') {
      const arrayValueFreeShipping = values.coupon_value && Array.isArray(values.coupon_value) && values.coupon_value.map((list) => ({
        id: list?.value,
        ...list
      }))
      formData.append('free_shipping', JSON.stringify(arrayValueFreeShipping))
    }
    if (values.coupon_type && values.coupon_type !== '' && values.coupon_type === 'voucher') {
      if (values.coupon_voucher_value && values.coupon_voucher_value !== '' && values.coupon_voucher_percentage == '') {
        formData.append('value_discount', values.coupon_voucher_value)
      }
      if (values.coupon_voucher_percentage && values.coupon_voucher_percentage !== '' && values.coupon_voucher_value == '') {
        formData.append('percentage_discount', values.coupon_voucher_percentage)
      }
    }
    if (values.apply_coupon_to === 'entire-product') {
      formData.append('is_all_products', true)
    } else {
      formData.append('is_all_products', false)
    }
    if (values.uses_per_coupon !== '') {
      formData.append('uses_per_coupon', values.uses_per_coupon)
    }
    if (values.uses_per_customer !== '') {
      formData.append('uses_per_customer', values.uses_per_customer)
    }

    const objCouponList = {}
    if (values.coupon_list_products.length > 0) {
      objCouponList.products = values.coupon_list_products.map((list) => ({
        id: list?.value,
        ...list
      }))

      if (values.coupon_list_product_combinations.length > 0) {
        objCouponList.products[0].combinations = values.coupon_list_product_combinations.map((list) => ({
          id: list?.value,
          ...list
        }))
      }
    }
    if (values.coupon_list_categories.length > 0) {
      objCouponList.categories = values.coupon_list_categories.map((list) => ({
        id: list?.value,
        ...list
      }))
    }
    if (values.coupon_list_subcategories.length > 0) {
      objCouponList.sub_categories = values.coupon_list_subcategories.map((list) => ({
        id: list?.value,
        ...list
      }))
    }
    if (values.coupon_list_brands.length > 0) {
      objCouponList.brands = values.coupon_list_brands.map((list) => ({
        id: list?.value,
        ...list
      }))
    }
    formData.append('applications', JSON.stringify(objCouponList))

    return formData
  }

  const handlePost = (payload, isDraft) => {
    if (isDraft) {
      setIsLoadingDraft(true)
    } else {
      setIsLoadingPublish(true)
    }
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/promotions/create`,
      data: payload
    })
      .then(({ data }) => {
        navigate('/coupons/list')
      })
      .catch((err) => {
        const errMessage = err?.response?.data?.message
        if (errMessage.split("-")[0] === 'duplicate') {
          showError("One/some of the products already has a promo coupon")
        }
      })
      .finally(() => {
        if (isDraft) {
          setIsLoadingDraft(false)
        } else {
          setIsLoadingPublish(false)
        }
      })
  }

  const handlePublish = (values) => {
    const inputPayload = handleInputValue(values, false)
    handlePost(inputPayload, false)
  }

  const handleDraft = () => {
    const inputPayload = handleInputValue(formik.values, true)
    handlePost(inputPayload, true)
  }

  const handleRemoveImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      coupon_images: values.coupon_images.filter(image => image !== file)
    }), false)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        coupon_images: attachments
      }), false)
    }
  }

  const handleImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        coupon_images: attachments
      }), false)
    }
  }

  const handleStartDateRange = (date) => {
    formik.setValues((values) => ({
      ...values,
      coupon_valid_start: date
    }), false)
  }

  const handleEndDateRange = (date) => {
    formik.setValues((values) => ({
      ...values,
      coupon_valid_end: date
    }), false)
  }

  const handleApplyCouponTo = (value) => {
    formik.setValues((values) => ({
      ...values,
      apply_coupon_to: value
    }), false)
  }

  const handleProductCouponList = (value, applyTo) => {
    if (applyTo == "selected-products") {
      formik.setValues((values) => ({
        ...values,
        coupon_list_products: value,
        coupon_list_product_combinations: [],
        coupon_list_categories: [],
        coupon_list_subcategories: [],
        coupon_list_brands: []
      }), false)
    }
    if (applyTo == "selected-products-combination") {
      formik.setValues((values) => ({
        ...values,
        coupon_list_product_combinations: value,
        coupon_list_categories: [],
        coupon_list_subcategories: [],
        coupon_list_brands: []
      }), false)
    }
    if (applyTo == "selected-categories") {
      formik.setValues((values) => ({
        ...values,
        coupon_list_categories: value,
        coupon_list_products: [],
        coupon_list_product_combinations: [],
        coupon_list_subcategories: [],
        coupon_list_brands: []
      }), false)
    }
    if (applyTo == "selected-subcategories") {
      formik.setValues((values) => ({
        ...values,
        coupon_list_subcategories: value,
        coupon_list_products: [],
        coupon_list_product_combinations: [],
        coupon_list_categories: [],
        coupon_list_brands: []
      }), false)
    }
    if (applyTo == "selected-brands") {
      formik.setValues((values) => ({
        ...values,
        coupon_list_brands: value,
        coupon_list_products: [],
        coupon_list_product_combinations: [],
        coupon_list_categories: [],
        coupon_list_subcategories: []
      }), false)
    }
  }

  const handleProductFreeShippingList = (value) => {
    formik.setValues((values) => ({
      ...values,
      coupon_value: value
    }), false)
  }

  const handleVoucherValue = (value, voucherType) => {
    if (voucherType == "fixed-amount") {
      formik.setValues((values) => ({
        ...values,
        coupon_voucher_value: value,
        coupon_voucher_percentage: ''
      }), false)
    }
    if (voucherType == "percentage") {
      formik.setValues((values) => ({
        ...values,
        coupon_voucher_percentage: value,
        coupon_voucher_value: ''
      }), false)
    }
  }

  const handleSelectCouponType = (e) => {
    const value = e.target.value
    if (value == "free-shipping") {
      formik.setValues((values) => ({
        ...values,
        coupon_type: value,
        coupon_value: '',
        apply_coupon_to: 'entire-product'
      }), false)
    } else {
      formik.setValues((values) => ({
        ...values,
        coupon_type: value,
        coupon_value: '',
      }), false)
    }
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Add New Coupon</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            couponTypeOptions={couponTypeOptions}
            openCouponPeriod={openCouponPeriod}
            setOpenCouponPeriod={setOpenCouponPeriod}
            handleApplyCouponTo={handleApplyCouponTo}
            handleRemoveImage={handleRemoveImage}
            handleFileInput={handleImageAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            handleStartDateRange={handleStartDateRange}
            handleEndDateRange={handleEndDateRange}
            handleMultiSelect={handleProductCouponList}
            handleShippingMethod={handleProductFreeShippingList}
            handleVoucherValue={handleVoucherValue}
            handleSelectCouponType={handleSelectCouponType}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormFirst
            isLoadingDraft={isLoadingDraft}
            isLoadingPublish={isLoadingPublish}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/coupons/list')}
            onDraft={() => handleDraft()}
            onPublish={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default AddCoupon
